.button {

  position: relative;

  svg {
    width: 20px;
    margin: -4.5px 5px -4.5px 0;
  }

}
