@use 'src/scss/shared_v2' as *;

.heading {

  margin-top: 0;
  color: rgba(0, 0, 0, 0.75);
  font-weight: bold;

  &--1 {
    font-size: 32px;
    line-height: 44px;

    @include desktop {
      font-size: 46px;
      line-height: 64px;
    }

  }

  &--2 {
    font-size: 24px;
    line-height: 34px;

    @include desktop {
      font-size: 32px;
      line-height: 44px;
    }

  }

  &--3 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 32px;
  }

  &--4 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;

    @include desktop {
      font-size: 20px;
      line-height: 28px;
    }

  }

  &--5 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

  &--6 {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
  }

}
