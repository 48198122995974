@use 'src/scss/shared_v2' as *;


:global {
  .gfp-app-wrap.-fixed {
      padding-top: 80.59px;
    @include tablet-only {
      padding-top: 53px;
    }
    @include mobile {
      padding-top: 56px;
    }
  }
  .gfp-app-wrap {
    &.-with-banner {
      padding-top: 128.18px;
    }
  }
}



.header {
  position: relative;
  padding: 16px 0 !important;
  font-family: $body-font-family;
  line-height: $body-line-height;
  box-sizing: border-box;

  @include mobile {
    padding-top: 10px;
  }

  &.-fixed {
    position: fixed!important;
    z-index: 9999;
    top: 0;
    min-width: 100% !important;
    transition: top 0.3s;
    &.-with-banner {
      top: 47.59px;
    }

    &.-scrolled {
      top: -80.59px
    }
  }

  @include tablet-only {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  &__inner {
    display: flex;
    align-items: center;
    width: 90% !important;

    @include fullhd {
      max-width: 1980px !important;
    }

  }

  &__logo {
    max-width: 156px;
    width: 100%;
    margin-right: auto;

    @include tablet {
      max-width: 173px;
    }

    @include desktop {
      max-width: 207px;
    }

    @include widescreen {
      order: 1;
      margin-right: 23px;
    }

    @include IE11 {
      svg {
        max-height: 24px;
      }
    }

  }

  &__menu {

    @include reset-ul;

    display: none;

    @include widescreen {

      display: flex;
      order: 3;
      margin-left: auto;
      &.-with-search {
        margin-left: 15px;
      }

      li {
        position: relative;
        margin-right: 30px;
        color: $c-dark-text;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
      }

      & > li {
        transition: color 0.2s;

        &.accent {
          color: $c-dark-text;
        }

        &:hover {
          color: $c-primary-link-hover;
        }

      }

      a {
        color: inherit;
        text-decoration: none!important;
      }

      li:global(.has-children) {

        &:after {

          content: "";
          display: inline-block;
          @include arrow-regular(currentColor, 45, 2.8, 1.9);
          margin-left: 6px;
          margin-bottom: 2px;

        }

        &:hover > ul {
          display: block;
        }

        ul {

          @include reset-ul;

          display: none;
          position: absolute;
          top: calc(100% + 6px);
          left: 0;
          min-width: 205px;
          padding: 8px 0;
          border-radius: 6px;
          background-color: #FFFFFF;
          box-shadow: 0 7px 16px -3px rgba(0, 0, 0, 0.39);
          z-index: 999;

          &:before {
            content: "";
            position: absolute;
            bottom: 100%;
            height: 6px;
            width: 100%;
          }

          li {
            margin: 0;

            &:hover {
              background-color: rgba($c-secondary, 0.4);
            }

            a {
              display: block;
              padding: 4px 16px;
              font-weight: 400;
            }

          }

        }

      }
      li:global(.has-sub-children) {
        font-weight: normal;
        padding: 4px 16px;

        &:hover > ul {
          display: block;
          top: -10px;
        }

        ul {

          @include reset-ul;

          display: none;
          position: absolute;
          top: calc(100% + 6px);
          left: auto;
          right: -206px;
          min-width: 205px;
          padding: 8px 0;
          border-radius: 6px;
          background-color: #FFFFFF;
          box-shadow: 0 7px 16px -3px rgba(0, 0, 0, 0.39);
          z-index: 999;

          &:before {
            content: "";
            position: absolute;
            bottom: 100%;
            height: 6px;
            width: 100%;
          }

          li {
            margin: 0;

            &:hover {
              background-color: rgba($c-secondary, 0.4);
            }

            a {
              display: block;
              padding: 4px 16px;
              font-weight: 400;
            }

          }

        }

      }

    }

    @include fullhd {

      li {
        margin-right: 40px;
      }

    }

  }

  &__search-box {
    margin-right: 16px;

    @include widescreen {
      display: flex;
      order: 3;
      margin-left: auto;
    }

  }

  &__request-demo-btn {
    order: 5;
    &.-book {
      font-size: 12px;
      padding: 14.5px 21px;
      @media screen and (max-width: 767px) {
        height: 32px;
        width: 63px;
        padding: 0;
      }
    }

    @include widescreen{
      margin-right: 16px;
    }
  }

  &__login-btn {
    order: 6;
    display: flex;

    svg{
      margin-right: 5px;
    }
  }

  &__formulator-btn {
    border-radius: 6px;
    background-color: #0046B7;
    color: #FFFFFF;
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 40px;
    text-align: center;
    padding: 1.7px 15px;
    order: 6;
    display: flex;
    &:hover {
      color: #FFFFFF!important;
      background-color: #003B99!important;
    }
  }


  &__menu-toggle {
    order: 7;
    width: 24px;
    height: auto;
    margin-left: 20px;
    @include mobile {
      box-sizing: border-box;
      width: 24px;
      object-fit: contain;
    }

    @include tablet {
      box-sizing: border-box;
      width: 40px;
      padding: 8px;
      border-radius: 6px;
      background-color: rgba(249, 224, 214, .2);
    }

    @include desktop {
      width: 48px;
      padding: 13.5px 12px;
    }

  }

}

.drawer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__menu {

    margin-bottom: auto;

    & > li {
      border-bottom: 1px solid $c-secondary;
    }

    a {
      display: block;
      padding: 30px 20px;
      color: #000000;
      font-weight: 600;
      font-size: 16px;
      text-decoration: none!important;
    }

    li:global(.has-children) {

      :global(.o-slide-down__icon) {
        right: 35px;
      }

      ul {

        background: #fff;

        a {
          padding: 12px 20px;
          font-size: 14px;
          font-weight: 400;
        }

      }

    }

  }

  &__app-btn {
    margin: 40px 35px;

    @include tablet {
      margin: 54px 52px;
    }

  }

  &__search-box-input-wrap {
    height: 60px;
  }

  &__search-box-icon {
    right: 30px;
  }

  &__search-box-input {
    padding-left: 20px;
    padding-right: 60px;
    width: 100%;
    border: none;
    background: #fff;
    border-radius: 0;
  }

}
:global {
  .-scrolled {
    top: -80.59px!important;
  }
}
