/* Beatrice */

@font-face {
  font-family: 'Beatrice';
  src: url('../../assets/fonts/Beatrice/Beatrice-Regular.ttf');
  src: url('../../assets/fonts/Beatrice/Beatrice-Regular.eot');
  src: url('../../assets/fonts/Beatrice/Beatrice-Regular.woff2') format('woff2'),
  url('../../assets/fonts/Beatrice/Beatrice-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Beatrice';
  src: url('../../assets/fonts/Beatrice/Beatrice-Semibold.ttf');
  src: url('../../assets/fonts/Beatrice/Beatrice-Semibold.eot');
  src: url('../../assets/fonts/Beatrice/Beatrice-Semibold.woff2') format('woff2'),
  url('../../assets/fonts/Beatrice/Beatrice-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Beatrice';
  src: url('../../assets/fonts/Beatrice/Beatrice-Bold.ttf');
  src: url('../../assets/fonts/Beatrice/Beatrice-Bold.eot');
  src: url('../../assets/fonts/Beatrice/Beatrice-Bold.woff2') format('woff2'),
  url('../../assets/fonts/Beatrice/Beatrice-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DM Serif Display';
  src: url('../../assets/fonts/DM/DMSerifDisplay-Regular.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito ExtraBold';
  src: url('../../assets/fonts/Nunito/Nunito-ExtraBold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Beatrice SemiBold';
  src: url('../../assets/fonts/Beatrice/Beatrice-Semibold.ttf');
  src: url('../../assets/fonts/Beatrice/Beatrice-Semibold.eot');
  src: url('../../assets/fonts/Beatrice/Beatrice-Semibold.woff2') format('woff2'),
  url('../../assets/fonts/Beatrice/Beatrice-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
