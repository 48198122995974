@use 'src/scss/shared_v2' as *;

.drawer {

  display: flex;
  flex-direction: column;
  background-color: $c-secondary-light;
  min-height: 100%;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 20px;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__logo {
    width: 169px;
  }

  &__close-icon {
    @include close-icon;
    opacity: 1;

    &:after, &:before {
      background-color: $c-primary;
    }

  }

}

