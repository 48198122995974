@use 'src/scss/shared_v2' as *;

.input {

  box-sizing: border-box;
  padding: 14px;
  border: 1px solid #DADADA;
  border-radius: 6px;
  outline: none;
  background-color: transparent;
  transition: 0.2s;
  font-size: 12px;
  box-shadow: none;
  color: $c-dark-text;

  /* Textarea */
  resize: none;

  &::placeholder {
    color: rgba(#000, .4);
    line-height: 21px;
    transition: inherit;
  }

  &:hover {
    border-color: #F9E0D6;
  }

  &:focus {
    border-color: #F9E0D6;

    &::placeholder {
      color: initial;
    }

  }

}
