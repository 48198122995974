@use 'sass:color';

$body-font-family: Beatrice;
$body-font-size: 16px;
$body-line-height: 24px;

$container: 1140px;

$tablet-breakpoint-raw: 1139;
$tablet-breakpoint: 1139px;
$tablet-container: 768px;

$mobile-breakpoint-raw: 767;
$mobile-breakpoint: 767px;
$mobile-container: 767px;

$aside-width: 380px;

$purple: #FF5C6A; /* rgb(255, 92, 106) */
$light-purple: color.adjust($purple, $lightness: 10%); /* #FF8F99 or rgb(255, 143, 153) */
$dark-purple: color.adjust($purple, $lightness: -10%); /* #FF293B or rgb(255, 41, 59) */

/*

  Changes pink -> coral

  Normal

  #DB0073 -> #FF5C6A
  rgba(219, 0, 115)
  219\s*,\s*0\s*,\s*115

  Lighten

  #F70084 -> #FF8F99
  rgba(247, 0, 132)
  247\s*,\s*0\s*,\s*132

  Darker

  #8C004B -> #FF293B
  rgba(140, 0, 75)
  140\s*,\s*0\s*,\s*75

  Grade Icons

  #771857

*/
