@use 'src/scss/shared_v2' as *;

.f-admin {
  min-height: 100vh;
  background-color: #F2F2F2;
  &__menu {
    max-width: 230px;
    min-width: 230px;
    width: 230px;
    margin-right: 24px;
    position: fixed;
    z-index: 9999;
    left: 24px;
    max-height: calc(100vh - 100px);
    &-beta {
      font-size: 8px;
      font-weight: normal;
      text-transform: initial;
    }
    &.-header-menu {
      position: fixed;
      z-index: 99999;
      border-radius: 6px;
      background-color: #F2F2F2;
      padding: 8px!important;
      left: 24px;
      top: 45px;
      display: none;
      transition: 0.2s;
      box-shadow: inset 0 0 0 1px #E0E0E0, 0 5px 10px -5px rgba(0,0,0,0.5);
        &.-open {
          display: block;
          transition: 0.2s;
        }
    }
    &-icon {
      width: 32px;
      height: 32px;
      background-color: #F2F2F2;
      border-radius: 6px;
      margin-right: 12px;
      &.-formulas {
        background-image: url('../../../assets/img/FormulatorAdmin/formulasMuted.svg');
      }
      &.-raw-materials {
        background-image: url('../../../assets/img/FormulatorAdmin/RawMaterialsMuted.svg');
      }
      &.-partners {
        background-image: url('../../../assets/img/FormulatorAdmin/PartnersMuted.svg');
      }
      &.-consumer {
        background-image: url('../../../assets/img/FormulatorAdmin/ConsumerDBSMuted.svg');
      }
      &.-reports {
        background-image: url('../../../assets/img/FormulatorAdmin/ReportsMuted.svg');
      }
      &.-library {
        background-image: url('../../../assets/img/FormulatorAdmin/LibraryMuted.svg');
      }
      &.-policies {
        background-image: url('../../../assets/img/FormulatorAdmin/PoliciesMuted.svg');
      }
      &.-consumer-study {
        background-image: url('../../../assets/img/FormulatorAdmin/OrgMenu-ConsumerStudies.svg');
      }
      &.-trash{
        background-image: url('../../../assets/img/FormulatorAdmin/TrashMuted.svg');
		}
      &.-insights {
        background-image: url('../../../assets/img/FormulatorAdmin/cara.svg');
      }
    }
    &-item {
      height: 56px;
      max-height: 56px;
      border-radius: 6px;
      background-color: #FAFAFA;

      box-shadow: 0 2px 4px -1px rgba(0,0,0,0.12);
      margin-bottom: 4px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      color: rgba(0,0,0,0.5);
      font-family: Beatrice, sans-serif;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0.28px;
      text-decoration: none;
      text-transform: uppercase;
      &.-trash {
        margin-top: 310px!important;
      }
      &.-library{
        font-size: 10px;
      }
      &.-opacity {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &.-active {
        background-color: #FFFFFF;
        color: rgba(0,0,0,0.8);
        .f-admin__menu-icon {
          &.-formulas {
            background-image: url('../../../assets/img/FormulatorAdmin/formulasMuted-active.svg');
          }
          &.-raw-materials {
            background-image: url('../../../assets/img/FormulatorAdmin/RawMaterialsActive.svg');
          }
          &.-partners {
            background-image: url('../../../assets/img/FormulatorAdmin/PartnersActive.svg');
          }
          &.-consumer {
            background-image: url('../../../assets/img/FormulatorAdmin/ConsumerDBSActive.svg');
          }
          &.-reports {
            background-image: url('../../../assets/img/FormulatorAdmin/ReportsActive.svg');
          }
          &.-library {
            background-image: url('../../../assets/img/FormulatorAdmin/LibraryActive.svg');
          }
          &.-policies {
            background-image: url('../../../assets/img/FormulatorAdmin/PoliciesActive.svg');
          }
          &.-consumer-study {
            background-image: url('../../../assets/img/FormulatorAdmin/OrgMenu-ConsumerStudiesActive.svg');
          }
          &.-trash {
            background-image: url('../../../assets/img/FormulatorAdmin/TrashActive.svg');
          }
          &.-insights {
            background-image: url('../../../assets/img/FormulatorAdmin/caraActive.svg');
          }
        }
      }
    }
  }
}
