@use 'src/scss/shared_v2' as *;

.modal {

  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $body-font-family;

  :global {

    form {
      width: 100%;
      max-width: 350px;
      margin-bottom: 15px;
      text-align: center;

      @include tablet {
        margin-bottom: 45px;
      }

      @include desktop {
        max-width: 432px;
        margin-bottom: 55px;
      }

    }

    input, textarea {
      display: block;
      width: 100%;
      margin-bottom: 13px;
      padding: 16px 15px;
      border: 1px solid #DADADA;
      border-radius: 6px;
      box-sizing: border-box;
      font-size: 12px;
      letter-spacing: 0.2px;

      @include desktop {
        padding: 20px;
        font-size: 14px;
        letter-spacing: -0.1px;
      }

    }

    textarea {
      margin-bottom: 20px;
      height: 152px;

      @include tablet {
        margin-bottom: 23px;
      }

      @include desktop {
        height: 137px;
        margin-bottom: 27px;
      }

      @include fullhd {
        height: 203px;
      }

    }

    button {
      width: 100%;

      @include desktop {
        max-width: 252px;
        font-size: 15px;
        letter-spacing: 0.23px;
      }

    }

  }

}

.heading {
  margin-top: 25px;
  margin-bottom: 22px;
  text-align: center;
  font-weight: 600;
  letter-spacing: -0.5px;

  @include tablet {
    margin-top: 20px;
  }

  @include desktop {
    margin-top: 27px;
  }

}

.success-msg {

  margin: 17px auto 44px;
  text-align: center;
  letter-spacing: -0.1px;
  font-weight: 600;

  @include tablet {
    max-width: 348px;
    margin-bottom: 36px;
  }

  @include desktop {
    max-width: 470px;
  }

  h2 {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 28px;
    font-weight: inherit;

    @include tablet-only {
      margin-bottom: 8px;
    }

    @include desktop {
      font-size: 32px;
      letter-spacing: -0.7px;
      line-height: 44px;
    }

  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 24px;

    @include desktop {
      font-size: 16px;

    }

  }

}
