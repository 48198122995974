@use 'src/scss/shared_v2' as *;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 99999;
}

.content {

  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: calc(100% - 32px);
  max-width: 520px;
  max-height: calc(100% - 32px);
  padding: 40px 20px 20px;
  border-radius: 10px;
  background: #FFFFFF;
  overflow: auto;
  outline: none;

  @include desktop {
    max-width: 600px;
  }
}

:global {
  .-wide {
    padding: 0;
    max-width: 720px;
    min-width: 720px;
  }
}

.close-icon {

  position: absolute;
  right: 10px;
  top: 10px;
  width: 38px;
  height: 38px;
  margin-left: auto;
  opacity: 0.6;
  cursor: pointer;
  z-index: 201;
  color: #9c9a9a;

  @include desktop {
    top: 13px;
    right: 13px;
  }

  &:before, &:after {
    position: absolute;
    top: 9px;
    left: 18px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: currentColor;
    border-radius: 1px;
    color: inherit;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

}

/* Open, Close animation */

.wrapper {

  :global {

    .ReactModal__Overlay {
      opacity: 0;
      transition: all 0.3s ease-out;
    }

    .ReactModal__Overlay--after-open {
      opacity: 1;
    }

    .ReactModal__Overlay--before-close {
      opacity: 0;
    }

    .ReactModal__Content {
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    .ReactModal__Content--after-open {
      opacity: 1;
    }

    .ReactModal__Content--before-close {
      opacity: 0;
    }

  }

}


