@use 'src/scss/shared_v2' as *;

.problem {
  text-align: center;
  padding-top: 50px;
  font-family: Beatrice,sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &-icon {
    height: 309px;
    width: 394px;
    background-image: url('../../assets/img/FormulatorAdmin/404.svg');
    margin-right: 50px;
  }
  &__title {
    color: rgba(0,0,0,0.75);
    font-family: Beatrice,sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: center;
  }
  a {
    color: #FF5C6A;
    font-family: Beatrice,sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 26px;
    text-transform: capitalize;
    &:hover {
      cursor: pointer;

    }
  }
}
