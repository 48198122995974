@use 'src/scss/shared_v2' as *;

.section {

  padding-top: 0 !important;
  padding-bottom: 0 !important;

  h1 {
    font-weight: 700;
    margin-bottom: 0.67em;
    margin-top:0;
  }
  h3, h2 {
    margin-bottom: 20px;
  }
  h5 {
    margin-bottom: 1.67em;
  }

  @include fullhd {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

}

.container {

  box-sizing: content-box;
  padding: 0 15px;

  @include tablet {
    padding: 0 24px;
  }

  @include desktop {
    padding: 0;
  }

  &--sm {

    @include desktop {
      max-width: 800px;
    }

    @include widescreen {
      max-width: 768px;
    }

    @include fullhd {
      max-width: 710px;
    }

  }

  &--md {

    @include widescreen {
      max-width: 955px;
    }

    @include fullhd {
      max-width: 1115px!important;
    }

  }

}
