@use 'src/scss/shared' as *;

$spinkit-spinner-margin: 40px !default;
$spinkit-size: 45px !default;
$spinkit-spinner-color: $dark-purple !default;

.loader {

  display: flex;
  align-items: center;
  justify-content: center;

  &--fw, &--cover {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 999;
  }

  &--fw {
    position: fixed;
  }

  &--cover {
    position: absolute;
  }

  &--transparent {
    background: rgba(255, 255, 255, 0.5);
  }

  &--pg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    transition: opacity .2s, transform .2s;
  }
}


.sk-fading-circle {
  $circleCount: 12;
  $animationDuration: 1.2s;

  margin: $spinkit-spinner-margin;
  width: $spinkit-size;
  height: $spinkit-size;
  position: relative;

  .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $spinkit-spinner-color;
    border-radius: 100%;
    animation: sk-circleFadeDelay $animationDuration infinite ease-in-out both;
  }

  @for $i from 2 through $circleCount {
    .sk-circle#{$i} {
      transform: rotate(360deg / $circleCount * ($i - 1));
    }
  }

  @for $i from 2 through $circleCount {
    .sk-circle#{$i}:before {
      animation-delay: - $animationDuration + $animationDuration / $circleCount * ($i - 1);
    }
  }

}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0
  }
  40% {
    opacity: 1
  }
}
