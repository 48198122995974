@use 'src/scss/shared_v2' as *;

.f-admin {
  &__snackbar {
    position: fixed;
    width: 480px;
    bottom: 16px;
    left: 50%;
    margin-left: -240px;
    padding: 10px 16px;
    border-radius: 6px;
    color: #ffffff;
    background-color: #303030;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.17);
    font-family: Beatrice, sans-serif;
    font-size: 14px;
    letter-spacing: -0.12px;
    line-height: 24px;
    transition: 0.5s;
    opacity: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1299px) {
      margin-left: -100px
    }
    &-btn {
      padding: 0 6px !important;
      text-decoration: none;
      border-radius: 2px;
      background-color: #0046b7;
      white-space: nowrap;
      color: #ffffff;
      font-family: Beatrice, sans-serif;
      font-size: 10px !important;
      font-weight: 600 !important;
      letter-spacing: 0.25px !important;
      line-height: 24px !important;
      text-align: center !important;
      margin: 0 10px;
      cursor: pointer!important;
    }
    &.-wide {
      width: 736px;
      transition: 0.1s;
    }
    &.-active {
      opacity: 1;
      z-index: 999999;
      &.-error {
        color: white;
        padding-left: 44px;
        &:before {
          content: "";
          position: absolute;
          left: 14px;
          top: 14px;
          background-image: url("../../assets/img/FormulatorAdmin/SnackbarError.svg");
          background-position: center;
          background-size: contain;
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}
