.svg-icon {

  display: flex;
  align-items: center;

  /* IE11 Fix */
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

  &[data-tooltip] {
    position: relative;
  }

  &[data-tooltip]:after {

    content: attr(data-tooltip);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -8px;
    top: -8px;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    background-color: #FFFFFF;

    opacity: 0.97;
    color: rgba(0, 0, 0, 0.7);
    font-family: "Cera Pro", sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;

  }

  &--disabled {
    opacity: 0.4;
  }

}

/* Grade icons styles */

.svg-icon-GradeA,
.svg-icon-GradeB {
  .st0{fill:#FFFFFF;}
  .st1{fill:#4A4A4A;}
  .st2{fill:#771857;}
  .st3{fill:#01BD01;}
}

.svg-icon-GradeC {
  .st0{fill:#FFFFFF;}
  .st1{fill:#4A4A4A;}
  .st2{fill:#771857;}
  .st3{fill:#F5A623;}
}

.svg-icon-GradeD,
.svg-icon-GradeE,
.svg-icon-GradeF {
  .st0{fill:#FFFFFF;}
  .st1{fill:#4A4A4A;}
  .st2{fill:#771857;}
  .st3{fill:#FF0000;}
}
