@use 'src/scss/shared_v2' as *;

.wrapper {

  * {
    box-sizing: border-box;
  }

  font-family: $body-font-family;
  line-height: $body-line-height;

  padding: 33px 0 12px 0 !important;

  @include desktop {
    padding-top: 44px !important;
    padding-bottom: 25px !important;
  }

  @include widescreen {
    max-width: 955px;
    margin-left: auto;
    margin-right: auto;
  }

  @include fullhd {
    max-width: 1115px;
    padding-top: 50px !important;
  }

}

.top-section {

  & > :global(.columns) {

    @include tablet {
      flex-direction: row-reverse;
    }

  }

}

.heading {
  margin-bottom: 14px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.1px;
}

a.heading {
  display: block;
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    color: $c-primary-link-hover;
  }

}

.connect-heading {

  margin-bottom: 18px;
  font-size: 20px;

  @include tablet {
    font-size: 16px;
    margin-bottom: 16px;
  }

}

.connect-column {

  @include tablet-only {
    padding-left: 50px;
    padding-right: 35px;
  }

  @include desktop-only {
    padding-right: 25px;
  }

  .link {
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
    text-decoration: none;
  }

}

.menus-column {

  :global .column {

    @include desktop {
      margin-bottom: 16px;
    }

  }

}

.subscription {
  margin-bottom: 8px;

  @include tablet {
    width: 100%;
  }

  @include desktop {
    width: 90%;
  }

  @include widescreen {
    width: 80%;
  }

  @include fullhd {
    width: 75%;
  }

}

.follow-us {
  margin-bottom: 8px;
}

.menu {

  @include reset-ul;

  display: flex;
  flex-wrap: wrap;
  margin-bottom: -5px;

  @include desktop {
    flex-direction: column;
  }

  li {

    margin-bottom: 16px;

    &:not(:last-child) {
      margin-right: 25px;
    }

  }

  a, span {
    color: rgba(0, 0, 0, 0.55);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.1px;
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      color: $c-primary-link-hover;
    }

    &:active {
      color: $c-primary-link-active;
    }

  }

}


.app-menu {
  flex-direction: column;

  li:first-child {
    margin-bottom: 20px;
  }

}

.ios-app-btn {

  display: block;
  width: 128px;
  padding: 0;
  background: transparent;

  svg {
    display: block;
    width: 100%;
  }

  path {
    transition: 0.2s;
  }

  &:not(:hover) path[fill="#FF5C6A"] {
    fill: #7C7C7C;
  }

}

.blog-section {
  margin-top: 7px;
  padding-top: 23px;
  border-top: 1px solid #E3E3E3;

  @include tablet {
    padding-top: 20px;
  }

  @include desktop {
    padding-top: 28px;
    margin-top: -18px;
  }

  :global {

    .columns {

      @include mobile {
        margin-top: 0;
        margin-bottom: 0;
      }

    }

    .column {

      @include mobile {
        padding-top: 0;
        padding-bottom: 0;
      }

    }

  }

}

.blog-menu {
  flex-direction: column;

  li {
    margin-right: 0 !important;
  }
}

.bottom-section {

  margin-top: 20px;
  color: #ACACAC;
  font-size: 12px;

  @include tablet {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 33px;
  }

  @include desktop {
    margin-top: 50px;
    margin-right: 71px;
  }

  @include widescreen {
    margin-right: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

}

.links-wrap {

  margin-right: 15px;

  @include desktop {
    display: flex;
  }

  a {

    transition: 0.2s;

    &:hover {
      color: $c-primary-link-hover;
    }

    &:active {
      color: $c-primary-link-active;
    }

  }

}

.links {

  margin-bottom: 9px;

  @include desktop {
    margin-bottom: 0;
  }

  a:after {
    content: "";
    display: inline-block;
    width: 3px;
    height: 3px;
    margin: auto 8px 2px;
    background-color: currentColor;
    border-radius: 50%;
  }

  a:last-child:after {

    display: none;

    @include desktop {
      display: inline-block;
    }

  }

}

.copyright {
  font-size: 11px;
  letter-spacing: -0.09px;

  @include tablet {
    font-size: 12px;
  }

}

.logo {

  display: block;
  max-width: 170px;
  margin-bottom: 34px;

  @include tablet {
    margin-bottom: 0;
  }

  svg {

    width: 100%;
    height: auto;

    @include IE11 {
      max-height: 44px;
      overflow: hidden;
    }

  }

}
