@use 'src/scss/shared_v2' as *;

.f-admin {
  background-color: #F2F2F2;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  &__add {
    padding: 0 !important;
    background: transparent;
    color: #0046B7 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    text-decoration: none;
    text-transform: none !important;
    padding-right: 16px !important;
    border-right: 1px solid #E0E0E0;
    font-family: Beatrice, sans-serif;
    &:last-child, &:nth-child(2) {
      padding-left: 16px !important;
    }
    &:last-child {
      border: none !important;
    }
    &.-incidental-button {
      padding-left: 16px !important;
      border-right: 1px solid #E0E0E0;
    }

    &-btns {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
  }
  &__td-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__td-centered {
    text-align: center;
    max-width: 200px;
    width: 200px;
  }
  &__sort {
    padding: 0 !important;
    background-image: url("../../../../assets/img/FormulatorAdmin/SortArrows.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 5px;
    background-color: transparent;
    height: 20px;
    width: 20px;
    margin-bottom: -5px;
    &.-active {
      background-image: url("../../../../assets/img/FormulatorAdmin/SortArrowsActive.svg");
    }
  }
  &__tab {
    padding: 0!important;
    margin: 0 16px;
    height: 56px;
    background: transparent;
    color: rgba(0, 0, 0, 0.8);
    font-family: Beatrice, sans-serif;
    font-size: 14px !important;
    letter-spacing: -0.12px !important;
    line-height: 24px !important;
    text-align: center !important;
    font-weight: normal !important;
    text-transform: none !important;

    &.-active {
      font-weight: 600!important;
      border-bottom: 3px solid #0046B7;
    }
  }
  &__tabs {
    display: flex;
    width: 100%;
    box-shadow: inset 0 -1px 0 0 #E0E0E0;
    padding-left: 16px;
    box-sizing: border-box;
    background: #FFFFFF;
  }
  &__header {
    &-flex {
      display: flex;
      height: 100%;
      align-items: center;
    }
    &-icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-right: 4px;
      background-image: url('../../../../assets/img/FormulatorAdmin/SaveGreen.svg');
    }
    &-btn {
      color: #FFFFFF!important;
      text-transform: capitalize!important;
      font-family: 'Beatrice', sans-serif;
      font-weight: 600!important;
      height: 40px;
      border-radius: 4px;
      margin-right: 16px;
      background-color: rgba(255, 255, 255, 0.07)!important;
      padding: 8px 10px 8px 6px !important;
      box-shadow: none!important;
      font-size: 14px;
      letter-spacing: -0.12px;
      text-decoration: none;
      align-items: center;
      display: flex;
    }
  }
  &__content-main {
    border-radius: 5px;
    padding: 0;
    border: 1px solid #E0E0E0;
    overflow: auto;
    &.-status {
      overflow: visible;
    }
    &-loaderContainer {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
  &__table {
    width: 100%;
    border-collapse: collapse;

    thead {
      border-radius: 15px!important;
      background-color: #F2F2F2!important;
    }

    .-search-option {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 8px 8px;
      color: rgba(0, 0, 0, 0.8);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      line-height: 20px;
      background-color: transparent;
      border: none;
      position: relative;
      justify-content: center;
      &:hover {
        cursor: pointer;
        background-color: #deebff;

      }

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 20px);
      }
      &.-all {
        color: rgba(0,0,0,0.8);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        font-weight: 600;
        border-bottom: 1px solid #E0E0E0;
      }
    }

    &.-presets {
      tbody {
        background-color: rgb(255, 255, 255);
      }
      &.-empty {
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        opacity: 0.6;
        thead {
          height: 50px;
        }
        tbody {
          height: 48px;
          tr {
            height: 48px;
            line-height: 48px;
            &:hover {
              background: transparent;
            }
          }
        }
      }
    }

    .status-actions {
      width: 82px;
    }
    .status-definitions {
      padding: 0!important;
      opacity: 1;
      color: rgba(0, 70, 183, 1);
      font-family: Beatrice, sans-serif;
      font-size: 10px;
      font-weight: 400!important;
      letter-spacing: 0;
      text-align: left;
      line-height: 12px;
      text-decoration: underline;
      text-transform: unset!important;
      margin-left: 6px;
      margin-bottom: 2px!important;
      position: absolute;
      height: 22px;
      background: transparent!important;
    }

    .status-th {
      width: 20%;
    }

    &-folder-row {
      &:hover {
        background-color: rgba(240, 240, 240, 0.5);
      }
      &.-status-managment {
        &:hover {
          cursor: pointer;
        }
      }
      &.-active {
        background-color: #FAFAFA;
        box-shadow: inset 0 1px 2px 0 #E0E0E0;
        td {
          padding-left: 0!important;
          &.-name {
            padding-left: 20px!important;
            input {
              width: 100%;
            }
          }
        }
        input {
          margin-left: -8px;
          color: rgba(0,0,0,0.8);
          font-family: Beatrice, sans-serif;
          font-size: 12px;
          letter-spacing: 0;
          padding: 8px;
          border: none;
          border-radius: 4px;
          background-color: #FFFFFF;
          box-shadow: inset 0 0 0 1px #E0E0E0;
          &:active, &:focus {
            outline: none!important;
            box-shadow: inset 0 0 0 1px #E0E0E0;
          }
        }
      }

      &-hover {
        &:hover {
          & > td > .f-profile__btns > button {
            background-color: rgb(255, 255, 255) !important;
            opacity: 1;
          }
          & > td > .f-profile__btns  > .f-admin__table-menu{
            background-image: url('../../../../assets/img/FormulatorAdmin/MenuBlue.svg')!important;
          }
          & > td > .f-profile__btns > .f-profile__reset {
            background-color: #0046B7!important;
            color: #fff;
          }
        }
      }
      &-hoverUser {
        &:hover {
          & > td > .f-profile__btns > .f-profile__reset {
            background-color: #0046B7!important;
            color: #fff;
          }
        }
      }
    }

    &-empty {
      color: rgba(0,0,0,0.5) !important;
      font-family: Beatrice, sans-serif !important;
      font-size: 12px !important;
      letter-spacing: 0 !important;
      line-height: 40px;
      text-align: center;
    }

    &-edit {
      height: 24px;
      width: 24px;
      background-size: 32px;
      background-position: -4px -4px;
      display: inline-block;
      background-image: url('../../../../assets/img/FormulatorAdmin/Edit.svg');
      border-radius: 2px;
      background-color: #f5f5f5 !important;
      margin-right: 2px;
      padding: 0!important;
      &.-status {
        background-color: #FAFAFA!important;
        background-image: url('../../../../assets/img/FormulatorAdmin/EditLight.svg');
      }
    }
    &-save {
      border-radius: 2px;
      background-color: #0046B7;
      color: #FFFFFF;
      font-family: Beatrice, sans-serif !important;
      font-size: 10px !important;
      font-weight: 600 !important;
      letter-spacing: 0.25px !important;
      white-space: nowrap !important;
      text-align: center !important;
      padding: 5px 7px !important;
      margin-right: 10px;
    }

    &-close {
      height: 12px;
      width: 12px;
      padding: 0 !important;
      background-size: contain;
      display: inline-block;
      border-radius: 2px;
      background-color: #f5f5f5 !important;
      background-image: url('../../../../assets/img/FormulatorAdmin/Close.svg');
    }

    &-menu {
      height: 24px;
      width: 24px;
      padding: 0 !important;
      margin-left: 2px;
      background-size: contain;
      display: inline-block;
      background-image: url('../../../../assets/img/FormulatorAdmin/Menu.svg');
      border-radius: 2px;
      background-repeat: no-repeat;
      &.-status {
        background-color: #FAFAFA;
        background-image: url('../../../../assets/img/FormulatorAdmin/MenuLight.svg');
      }
      &-light-background {
        background-color: rgba(240, 240, 240, 0.5);
      }
      &-active {
        background-image: url('../../../../assets/img/FormulatorAdmin/MenuBlue.svg');
      }
    }
    &-user-settings-icon {
      height: 24px;
      width: 24px;
      padding: 0 !important;
      margin-left: 2px;
      background-size: 24px 24px;
      display: inline-block;
      background-image: url("../../../../assets/img/FormulatorAdmin/userDetails.svg");
      &.disabled {
        opacity: 0.5!important;
      }
    }

    &-user-settings-transparent-icon {
      height: 24px;
      width: 24px;
      padding: 0 !important;
      margin-left: 2px;
      background-size: 24px 24px;
      display: inline-block;
      background-color: rgba(240, 240, 240, 0.5);
      background-image: url("../../../../assets/img/FormulatorAdmin/userDetailsFillTransparent.svg");
      opacity: 0.5;
      &.disabled {
        opacity: 0.5;
      }
    }

    &-edit-icon {
      height: 24px;
      width: 24px;
      padding: 0 !important;
      margin-left: 2px;
      background-size: 32px 32px;
      display: inline-block;
      background-color: rgba(240, 240, 240, 0.5);
      background-image: url("../../../../assets/img/FormulatorAdmin/EditActive.svg");
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.5;
      &.disabled {
        opacity: 0.25 !important;
      }
    }

    &-team-details {
      height: 24px;
      width: 24px;
      padding: 0 !important;
      margin-left: 2px;
      background-size: 24px 24px;
      display: inline-block;
      background-color: rgba(240, 240, 240, 0.5);
      background-image: url("../../../../assets/img/FormulatorAdmin/TeamDetails.svg");
      opacity: 0.5;
      &.disabled {
        opacity: 0.25 !important;
      }
    }

    &-delete-icon {
      height: 24px;
      width: 24px;
      padding: 0 !important;
      margin-left: 2px;
      background-size: 24px 24px;
      display: inline-block;
      background-color: rgba(240, 240, 240, 0.5);
      background-image: url("../../../../assets/img/FormulatorAdmin/DeleteActive.svg");
      opacity: 0.5;
      &.disabled {
        opacity: 0.25 !important;
      }
    }

    th {
      padding: 14px;
      color: rgba(0,0,0,0.5);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: left;
      position: sticky;
      top: 0;
      background-color: #F2F2F2!important;
      max-width: 30%;
      min-width: 10%;
      &.-centered {
        text-align: center;
        width: 66px;
        min-width: 66px;
      }
      &.user-email {
        width: 25%;
      }
      &.user-role {
        width: 25%;
        min-width: 140px;
      }
      &.user-actions {
        width: 200px;
      }
      &.team-actions {
        width: 100px;
      }
      &:first-child {
        border-top-left-radius: 5px;
      }

      &.-preset-name, &.-policies-count {
        width: 50%;
        max-width: calc(50% - 82px);
        min-width: calc(50% - 82px);
      }
    }
    tr {
      padding-right: 20px !important;
      box-shadow: inset 0 -1px 0 0 #F2F2F2;
    }

    td {
      padding: 14px !important;
      color: rgba(0, 0, 0, 0.8);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      vertical-align: middle;
      position: relative;

      &.-logic {
        :global {
          .-checkmark-logic {
            display: inline-block;
            height: 16px;
            width: 16px;
            background-image: url('../../../../assets/img/FormulatorAdmin/Checkmark-green.svg');
            border-radius: 2px;
            //background-color: rgba(255, 255, 255, 1);
            margin-bottom: -3px;
          }
        }

      }

      &.user-teams {
        padding: 0 10px 0 8px !important;
      }

      &.-none {
        color: rgba(0,0,0,0.5);
      }

      .-more {
        color: #0046B7;
        font-size: 12px;
        font-weight: 600;
      }

      .-default {
        color: rgba(0, 0, 0, 0.5);
        font-family: Beatrice, sans-serif;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 12px;
        font-weight: normal;

        margin-left: 4px;
      }

      .status-icon {
        display: block;
        height: 16px;
        width: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &.-option {
          width: 16px;
        }
        &.-grey_clock_icon {
          background-image: url("../../../../assets/img/FormulatorAdmin/Pending.svg");
          opacity: 0.35;
        }
        &.-red_x_icon {
          background-image: url("../../../../assets/img/FormulatorAdmin/DeleteCrossRed.svg");
        }
        &.-green_check_icon {
          background-image: url("../../../../assets/img/FormulatorAdmin/Checkmark-green.svg");
        }
      }
    }

    &-teams {
      td {
        padding: 8px 10px 8px 8px !important;
        color: rgba(0, 0, 0, 0.8);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        vertical-align: middle;
        position: relative;

        &.user-email {
          width: 300px;
        }
        &.team-users {
          padding: 0 10px 0 8px !important;
        }
      }
    }

    &-warning {
      display: flex;
      align-items: flex-start;
      border-radius: 6px;
      background-color: #FAFAFA;
      width: 100%;
      padding: 8px;
      &-container {
        display: flex;
        flex-wrap: wrap;
        .-scrollable-area {
          max-height: 200px;
          overflow: auto;
          br {
            display: block;
            margin: 0;
            content: " ";
            padding: 5px;
          }
        }
      }
      &-count {
        color: rgba(0,0,0,0.8);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        margin-right: 8px;
      }
      &-comment {
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: inset 0 0 0 1px #E0E0E0, 0 5px 10px -5px rgba(0,0,0,0.5);
        display: none;
        color: rgba(0,0,0,0.8);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
        position: fixed;
        min-width: 220px;
        padding: 20px;
        z-index: 999999999;
        left: 50%;
        transform: translate(-50%, 0);
        max-width: 200px;

        &-absolute {
          position: absolute;
        }

        .-scroll {
          max-height: 300px;
          overflow: auto;
          word-break: break-all;
          white-space: break-spaces;
          br {
            display: block;
            margin: 0;
            content: " ";
            padding: 5px;
          }
        }
        &.-revert {
          white-space: nowrap;
          padding: 10px;
        }
        &:after,
        &:before {
          content: "";
          display: block;
          position: absolute;
          bottom: -20px;
          width: 0;
          height: 0;
        }

        &:after {
          left: 50%;
          border: 10px solid transparent;
          border-bottom-color: #fff;
          transform: rotate(180deg) translate(50%, 0);
        }

        &:before {
          left: 50%;
          border: 11px solid transparent;
          border-bottom-color: #ffffff;
          transform: rotate(180deg) translate(50%, 0);
        }
        &.bottom {
          box-shadow: inset 0 0 0 1px #E0E0E0, 0 -5px 10px -5px rgba(0,0,0,0.5);
          &:after,
          &:before {
            bottom: unset;
            transform: unset;
            top: -20px;
          }
        }
      }
      &-item {
        position: relative;
        margin-right: 4px;
        &.-warning {
          background-image: url('../../../../assets/img/FormulatorAdmin/WarningFlag.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          height: 16px;
          width: 16px;
          margin-right: 0;
        }
        &:hover {
          .f-admin__table-warning-comment {
            display: block;
          }
          cursor: pointer;
        }
      }
    }

    &-input {
      min-height: 24px;
      width: calc(100% - 16px);
      padding: 4px 6px;
      line-height: 24px;
      border-radius: 4px;
      border: none;
      background-color: #FFFFFF;
      box-shadow: inset 0 0 0 1px #E0E0E0;
      &:active, &:focus-visible {
        border: none;
        outline: none;
      }
    }

    &-text-wrap {
      display: flex;
      align-items: center;
      padding: 4px 6px;
      min-height: 24px;
      overflow: hidden;
      justify-content: flex-start;
      &-overflow {
        align-items: center;
        max-height: 24px;
        max-width: 300px;
        line-height: 24px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: normal;
        word-break: break-all;
        text-overflow: ellipsis;
      }
      &-noPadding {
        padding: 0;
      }
      &-longListWrapper {
        padding: 12px 6px;
      }
    }
    &-select {
      width: 100%;
      margin: 0;
      box-sizing: border-box;

      &-option-region {
        font-weight: 600;
      }

      &-noOptions-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-text {
          color: rgba(0,0,0,0.5);
          font-family: Beatrice, sans-serif;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 20px;
        }

        &-button {
          width: auto;
          padding: 0;
          background-color: transparent;
          color: #0046B7;
          font-family: Beatrice, sans-serif;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 20px;
          text-transform: none;
        }
      }

      &.-single {
        :global {
          .react-select__clear-indicator {
            display: flex !important;
          }
        }
      }

      &.-rm {
        input {
          box-shadow: none!important;
          &::placeholder {
            text-align: left!important;
          }
        }
        :global {
          .react-select__control {
            width: 100%;
            min-height: 32px;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0;
            border-radius: 4px;
            padding-left: 3px;
            margin: auto;
          }
          .react-select__multi-value {
            max-width: 90%;
          }
          .react-select__indicator {
            background: transparent;
          }.react-select__placeholder {
             text-align: left;
           }
          .react-select__dropdown-indicator:after {
            height: 7px;
          }

          .react-select__menu {
            &-list {
              &::-webkit-scrollbar {
                width: 11px;
              }

              &::-webkit-scrollbar-thumb {
                background: #7F7F7F;
                border-radius: 7px;
                border: 2px transparent solid;
                background-clip: padding-box;
              }
            }
          }
        }
      }

      :global {

        .react-select__menu {
          font-size: 12px !important;
          font-weight: normal !important;
        }

        .react-select__value-container {
          padding: 0;
          margin: 0;
        }

        .react-select__indicator {
          padding: 0;
        }

        .react-select__input-container {
          padding: 0;
          margin: 0;
        }

        .react-select__multi-value {
          height: 24px;
          display: flex;
          align-items: center;
          border-radius: 4px;
          background-color: #FAFAFA;
          margin: 2px 4px 2px 0;

          &__label {
            font-size: 12px;
            line-height: 20px;
            padding: 2px 3px;
          }
        }

        .react-select__multi-value__remove {
          opacity: 0.5;

          svg {
            width: 10px;
          }

          &:hover {
            background: transparent;
            color: #000000;
          }
        }

        .react-select__dropdown-indicator {
          height: 30px;
          width: 30px;
          border-radius: 5px;
          background-color: #F2F2F2;
          display: flex;
          align-items: center;
          justify-content: center;

          &:after {
            content: '';
            height: 16px;
            width: 8px;
            display: block;
            background-image: url("../../../../assets/img/FormulatorAdmin/CaretBig_dark.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transform: rotate(0deg);
          }

          svg {
            display: none;
          }

        }

        .react-select__clear-indicator, .react-select__indicator-separator {
          display: none;
        }

        .react-select__placeholder {
          padding-left: 0;
          line-height: 1;
        }

        .react-select__option {
          color: rgba(0,0,0,0.5);
          font-family: Beatrice, sans-serif;
          font-size: 12px;
        }

        .react-select__control {
          padding: 5px 6px 5px 12px;
          width: 100%;
          border: none;
          border-radius: 6px;
          background-color: #FFFFFF;
          box-shadow: inset 0 0 0 1px #E0E0E0;
          font-family: Beatrice, sans-serif;
          font-size: 12px;
          font-weight: normal;

          input::placeholder {
            color: rgba(0,0,0,0.5);
            font-family: Beatrice, sans-serif;
            font-size: 12px;
            font-weight: normal;
          }
        }
      }

      &.-search-icon {
        :global {

          .react-select__value-container {
            position: relative;
          }

          .react-select__indicator {
            display: none;
          }

          .react-select__value-container--has-value {
            &:before {
              top: -2px;
            }
          }

        }
      }
    }
    &-loader {
      width: 100%;
      display: flex;
      height: 32px;
      justify-content: center;
      align-items: center;
      & > .loader-raw {
        width: 32px;
        height: 32px;
      }
      &-main {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        & > .loader-main {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
  &__add {
    padding: 0 !important;
    background: transparent;
    color: #0046B7 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    text-decoration: none;
    text-transform: none !important;
    padding-right: 16px !important;
    border-right: 1px solid #E0E0E0;
    font-family: Beatrice, sans-serif;

    &-btns {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
  }
  &_menu {
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: inset 0 0 0 1px #E0E0E0, 0 5px 10px -5px rgba(0,0,0,0.5);
    z-index: 999;
    position: absolute;
    top: 120px;
    right: 60px;
    width: 180px;
    padding: 8px 4px;

    &-table-settings {
      right: 0;
      width: 180px;
      box-sizing: border-box;
    }

    &_bottom-menu {
      top: unset;
      left: 0;
      bottom: 50px;
      width: 150px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 12px;
      font-family: Beatrice, sans-serif;
      font-weight: normal;
    }

    &-options {
      text-align: start;

      &-option {
        color: rgba(0, 0, 0, 0.8);
        padding: 4px 0 4px 6px;
        display: flex;
        align-items: center;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
        font-family: Beatrice, sans-serif;
        justify-content: space-between;
        &:hover {
          cursor: pointer;
        }
        &-delete {
          color: red;
        }
        .-beta {
          font-size: 9px;
        }


        :global {
          .-loader {
            width: 20px;
            height: 20px;
            left: 0;
            margin-right: 10px;
          }
        }
        &:hover {
          background-color: #EFEFEF;
        }
      }
    }
  }
}
.f-profile {

  &__divWidth {
    width: 100%;
  }
  &__divMarginWidth {
    width: 100%;
    margin-left: 16px;
  }
  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    &.flex-end {
      justify-content: flex-end;
    }
    &-save {
      border-radius: 2px;
      text-transform: uppercase;
      background-color: #0046B7;
      color: #FFFFFF;
      font-family: Beatrice, sans-serif !important;
      font-size: 10px !important;
      font-weight: 600 !important;
      letter-spacing: 0.25px !important;
      white-space: nowrap !important;
      text-align: center !important;
      padding: 5px 7px !important;
      margin-right: 10px;
      &:hover {
        background-color: #0046B7 !important;
      }
    }
  }
  &__reset {
    color: rgba(0,0,0,0.5);
    font-family: Beatrice, sans-serif;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 24px;
    text-align: center;
    border-radius: 2px;
    background-color: rgba(240,240, 240, 0.3);
    padding: 0 6px;
    text-transform: uppercase;
    &:hover, &:active, &:focus {
      background-color: rgba(240, 240, 240, 0.5) !important;
    }
  }
  &__input-error {
    box-shadow: inset 0 0 0 1px rgba(255,92,106,0.6) !important;
    border-radius: 6px;
    border: 1px solid red!important;
  }
  &__delete {
    color: #ffffff;
    font-family: Beatrice, sans-serif;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 24px;
    text-align: center;
    border-radius: 2px;
    background-color: #ff5c6a;
    padding: 0 6px;
    margin-left: 10px;
    text-transform: uppercase;
  }
  &__checkerboard {
    display: flex;
    flex-wrap: wrap;
    background-color: rgba(0,0,0,0.3);
    background-image:
            linear-gradient(45deg, #808080 25%, transparent 25%),
            linear-gradient(-45deg, #808080 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, #808080 75%),
            linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 10px 10px;
    background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
    z-index: 0;
  }
  &__image {
    width: 120px;
    height: 72px;
    object-fit: contain;
    object-position: top;
    &-remove {
      height: 40px;
      width: 40px;
      border-radius: 6px;
      background-color: #FAFAFA;
      background-image: url('../../../../assets/img/FormulatorAdmin/DeleteDark.svg');
      background-repeat: no-repeat;
      background-position: center;
      padding: 0!important;
      background-size: 24px;
      margin-right: 15px;
      &:hover {
        background-color: #FAFAFA!important;
      }
    }
    &-row {
      margin-bottom: 8px;
    }
    &-recommended {
      margin-left: 16px;
      color: rgba(0,0,0,0.5);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
  &__btn-claim {
    height: 40px;
    width: 97px;
    border-radius: 6px;
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0,0,0,0.8);
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.18px;
    &:hover {
      background-color: #F2F2F2!important;
      color: rgba(0,0,0,0.8) !important;

    }
  }
  &__btn-edit {
    width: 95px;
    justify-content: center;
    line-height: 40px;
    color: #FFFFFF!important;
    text-transform: capitalize!important;
    font-family: 'Beatrice', sans-serif;
    font-weight: 600!important;
    height: 56px;
    border-radius: 0 6px 0 0;
    background: #FF5C6A!important;
    box-shadow: none!important;
    font-size: 14px;
    letter-spacing: -0.12px;
    text-decoration: none;
    align-items: center;
    display: flex;
  }
  &__divider {
    height: 1px;
    box-shadow: inset 0 -1px 0 0 #E0E0E0;
    margin-bottom: 32px;
  }
  &__row {
    margin: 0;
    padding: 8px 0px 16px;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-info {
      color: rgba(0,0,0,0.5);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
    }
    &-link {
      width: calc(100% - 13px)!important;
    }
    &-phone-input {
      border: none !important;
      cursor: text !important;
      width: 100% !important;
    }
    &-phone-input-editMode {
      cursor: text !important;
      width: 100% !important;
    }
    &-flag-dropdown {
      border: none!important;
      :global {
        .arrow {
          display: none!important;
        }
      }
    }

    &-logo {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 16px;
    }

    &-boxShadow {
      box-shadow: inset 0 -1px 0 0 #F2F2F2;
    }
    &-first {
      padding: 8px 0px 16px;
      box-sizing: border-box;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 24px 0 0;

      div {
        width: 100%;
      }
    }
    &-referral-container {
      box-shadow: inset 0 -1px 0 0 #F2F2F2;
      padding: 8px 8px 24px;
      box-sizing: border-box;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      max-width: 640px;
      div {
        width: calc(100% - 97px);
      }
    }
    &-last {
      margin: 8px 0 16px;
      box-sizing: border-box;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      box-shadow: none;
    }
    &-label {
      color: rgba(0,0,0,0.8);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      margin-top: 16px;
      margin-bottom: 4px;
      &-disabled {
        opacity: 0.5;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
    &-input {
      color: rgba(0,0,0,0.8);
      font-family: Beatrice, sans-serif;
      font-size: 14px;
      letter-spacing: -0.12px;
      line-height: 24px;
      width: calc(100% - 13px);
      padding: 10px 0 6px 12px;
      background: transparent;
      border: 1px solid lightgray;
      border-radius: 6px;
    }
    &-name {
      color: rgba(0,0,0,0.8);
      font-family: Beatrice, sans-serif;
      font-size: 14px;
      letter-spacing: -0.12px;
      line-height: 24px;
      border: none;
      width: 100%;
      background: transparent;
      a {
        color: rgba(0,0,0,0.8);
        text-decoration: none;
      }
      .-api {
        padding: 6px 0;
      }
      .sample-url {
        color: #0046B7;
        padding-bottom: 6px;
        word-break: break-all;
        a {
          color: #0046B7;
        }
      }
      &:hover, &:active, &:focus {
        outline: none!important;
        box-shadow: none!important;
      }
    }
    &-how-it-works {
      line-height: 14px;
      font-size: 10px;
      padding: 6px;
      border-radius: 2px;
      background: #F2F2F2;
      display: inline-block;
      height: 12px;
      color: rgba(0,0,0,0.5) !important;
      font-weight: bold;
    }
    &-referral-text {
      color: rgba(0,0,0,0.5);
      font-family: Beatrice, sans-serif;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: -0.12px;
      line-height: 24px;
      border: none;
      background: transparent;
    }
  }
  &__sub-small {
    color: rgba(0,0,0,0.5);
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 36px;
  }
  &__sub-label {
    color: rgba(0,0,0,0.8);
    font-family: Beatrice, sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.12px;
    line-height: 24px;
    margin-bottom: 20px;
    &.-space {
      margin-top: 54px;
    }
    &-grey {
      color: rgba(0,0,0,0.5);
      font-weight: normal;
      margin-bottom: 9px;
    }
  }
  &__table-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0,0,0,0.5);
    font-family: Beatrice, sans-serif;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.12px;
    line-height: 24px;
    margin-bottom: -12px;
  }
  &__RequestBtn {
    width: 172px;
    height: 40px;
    color: #FFFFFF!important;
    text-transform: capitalize!important;
    font-family: 'Beatrice', sans-serif;
    font-weight: 600!important;
    border-radius: 6px;
    background: #0046B7 !important;
    box-shadow: none !important;
    font-size: 12px !important;
    letter-spacing: -0.12px !important;
    text-decoration: none !important;
    align-items: center !important;
    display: flex !important;
  }
  &__drag-icon {
    background-image: url("../../../../assets/img/FormulatorAdmin/DragArrows.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    &.-dragable {
      background-image: url("../../../../assets/img/FormulatorAdmin/SortArrowsActive.svg");
    }
    &:hover {
      cursor: move;
    }
  }
  &__menu {
    height: max-content;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 330px;
    max-width: 330px!important;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.12);
    &.-additional {
      margin-top: 12px;
      height: 64px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      color: #0046B7;
      font-family: Beatrice, sans-serif;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.12px;
      text-decoration: none;
      .f-profile__menu-link {
        display: block;
        width: 32px;
        height: 32px;
        margin-right: 12px;
        background-image: url('../../../../assets/img/FormulatorAdmin/FormulasLink.svg');
      }
    }
    &-drag {
      width: 16px;
    }
    &-list {
      padding: 16px 14px;
      &-item {
        display: flex;
        align-items: center;
        padding: 10px 14px;
        text-align: left;
        text-decoration: none;
        &.-active {
          border-radius: 6px;
          background-color: #F2F2F2;
          .f-profile__menu-list-icon {
            &.-profile {
              background-image: url('../../../../assets/img/FormulatorAdmin/ProfileDetailsActive.svg');
            }
            &.-security {
              background-image: url('../../../../assets/img/FormulatorAdmin/LoginSecurityActive.svg');
            }
            &.-billing {
              background-image: url('../../../../assets/img/FormulatorAdmin/BillingSubscriptionActive.svg');
            }
          }
        }
      }
      &-icon {
        display: block;
        height: 32px;
        width: 32px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 16px;
        &.-profile {
          background-image: url('../../../../assets/img/FormulatorAdmin/ProfileDetailsMuted.svg');

        }
        &.-security {
          background-image: url('../../../../assets/img/FormulatorAdmin/LoginSecurityMuted.svg');

        }
        &.-billing {
          background-image: url('../../../../assets/img/FormulatorAdmin/BillingSubscriptionMuted.svg');
        }
        &.-management {
          height: 24px;
          width: 24px;
          margin-right: 20px;
          margin-left: 4px;
          background-color: #F2F2F2;
          opacity: 0.8;
          color: rgba(0,0,0,0.8);
          font-family: Beatrice, sans-serif;
          font-size: 12px;
          font-weight: 600;
          text-align: center;
          border-radius: 100%;
          font-style: unset;
        }
      }
      &-name {
        color: rgba(0,0,0,0.8);
        font-family: Beatrice, sans-serif;
        font-size: 14px;
        letter-spacing: -0.12px;
        line-height: 24px;
      }
    }
    &-full {
      width: 100%;
      &-name {
        color: rgba(0,0,0,0.8);
        font-family: Beatrice, sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.12px;
        line-height: 24px;
      }
      &-email {
        color: rgba(0,0,0,0.5);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
      }
      &-checkMarkIcon {
        height: 16px;
        width: 16px;
        background-image: url('../../../../assets/img/FormulatorAdmin/Checkmark-green.svg');
        background-repeat: no-repeat;
        background-position: center;
        padding: 0!important;
      }
    }
    &-short {
      height: 32px;
      width: 32px;
      min-width: 32px;
      color: rgba(0,0,0,0.5);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: -0.12px;
      line-height: 24px;
      text-align: center;
      border-radius: 24px;
      background-color: #F2F2F2;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }
    &-role {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      &.-flex-wrap {
        flex-wrap: wrap;
      }
    }
    &-name {
      display: flex;
      align-items: center;
      padding: 2px 0 5px 0;


    }
  }
  &__container {
    margin-left: 20px!important;
    width: 100%;
    max-width: 100% !important;
    &.-more-bottom-space {
      margin-bottom: 200px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px 6px 0 0;
    background-color: #0046B7;
    width: 100%;
    max-width: 100% !important;
    height: 56px;
    padding-left: 24px;
    color: #FFFFFF;
    font-family: 'Beatrice', sans-serif;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
    &-flex {
      display: flex;
      height: 100%;
      align-items: center;
    }
    &.-editing {
      padding-left: 0;
    }
    &-back {
      width: 56px;
      box-shadow: inset -1px 0 0 0 rgba(255,255,255,0.2);
      background-image: url('../../../../assets/img/FormulatorAdmin/BackIcon.svg');
      background-size: 32px;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      border-radius: 0;
      margin-right: 10px;
      &:hover {
        background-color: transparent!important;
      }
    }
    &-btn {
      color: #FFFFFF!important;
      text-transform: capitalize!important;
      font-family: 'Beatrice', sans-serif;
      font-weight: 600!important;
      height: 56px;
      border-radius: 0 6px 0 0;
      background: #FF5C6A!important;
      box-shadow: none!important;
      font-size: 14px;
      letter-spacing: -0.12px;
      text-decoration: none;
      align-items: center;
      display: flex;
      &.-clear {
        height: 40px;
        width: 80px;
        border-radius: 6px;
        background-color: #F2F2F2!important;
        color: rgba(0,0,0,0.8) !important;
        font-family: Beatrice, sans-serif;
        font-size: 12px!important;
        font-weight: 600;
        letter-spacing: 0.18px;
      }
      &.-apply {
        height: 40px;
        width: 80px;
        border-radius: 6px;
        background-color: #0046B7!important;
        color: #FFFFFF !important;
        font-family: Beatrice, sans-serif;
        font-size: 12px!important;
        font-weight: 600;
        letter-spacing: 0.18px;
        margin-right: 8px;
      }
    }
  }
  &__flex {
    display: flex;
  }
  &__content {
    padding: 16px;
    &-main {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 5px;
      position: relative;
      background: #ffffff;
      padding: 32px calc(100% - 660px) 32px 32px;
      width: 100%;
    }
    &-users {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 5px;
      background: #ffffff;
      padding: 32px;
      width: 100%;
      min-height: calc(100vh - 256px);
      height: fit-content;
    }
    &-teams {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 5px;
      background: #ffffff;
      padding: 32px 32px 80px;
      margin-bottom: 140px;
      width: 100%;
      min-height: calc(100vh - 256px);
      height: fit-content;
    }
    &-status {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 5px;
      background: #ffffff;
      padding: 32px;
      width: 100%;
      min-height: calc(100vh - 256px);
      &.-empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-teams-table {
      border-radius: 5px;
      padding: 0;
      border: 1px solid #E0E0E0;
      overflow: visible;
    }
  }
  &__formula_preferences {
    width: 100%;
    margin-top: 48px;
    padding-bottom: 18px;
    &_wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-height: 27px;
      margin-top: 13px;
      &-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 20px;
        font-family: Beatrice, sans-serif;
      }
    }
    &_automated-numbering {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      margin-top: 13px;
      .-title {
        color: rgba(0, 0, 0, 0.8);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        line-height: 20px;
      }
      .-info {
        color: rgba(0, 0, 0, 0.5);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        line-height: 20px;
        margin-top: 4px;
      }
      .-input-reference {
        border-radius: 6px;
        box-shadow: 0 0 0 1px rgba(224, 224, 224, 1) inset;
        opacity: 1;
        border: none;
        padding: 10px 12px;
        background-color: rgba(255, 255, 255, 1);
        color: rgba(0, 0, 0, 0.5);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        line-height: 20px;
        width: calc(61% + 16px);
        margin-top: 8px;
        outline: none !important;
      }
      .-number-text {
        color: rgba(0, 0, 0, 0.8);
        font-family: Beatrice, sans-serif;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.12px;
        text-align: left;
        line-height: 24px;
        margin-top: 4px;
      }
    }
    &_title {
      width: 61%;
      .-tooltip-container {
        height: 16px;
        margin-bottom: -4px;
      }
    }
    &_status {
      display: flex;
      align-items: center;
      width: 39%;
      height: 27px;
    }
    &_checkmark {
      background-image: url("../../../../assets/img/pages-b2b/Compare/Checkmark-green.svg");
      background-position: center;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
    &_crossmark {
      background-image: url('../../../../assets/img/FormulatorAdmin/Crossmark-red.svg');
      background-position: center;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
}

.presets {
  &__container {
    border-radius: 6px;
    margin-bottom: 12px;
    opacity: 1;
    padding: 16px;
    box-sizing: border-box;
    background-color: rgba(250, 250, 250, 1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    &-topBlock {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }
    &-tableBlock {
      margin-top: 16px;
      width: 100%;
      border-radius: 5px;
      border: 1px solid #E0E0E0;
      &-loader {
        display: flex;
        justify-content: center;
        border: none !important;
      }
    }
  }
  &__header {
    opacity: 1;
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Beatrice', sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.12px;
    text-align: left;
    line-height: 24px;
  }
  &__btnBlock {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    &-addPreset {
      border-radius: 2px;
      text-transform: uppercase;
      opacity: 1;
      background-color: rgba(0, 70, 183, 1);
      color: rgba(255, 255, 255, 1);
      font-family: 'Beatrice', sans-serif;
      padding: 6px;
      margin-right: 24px;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0.25px;
      text-align: center;
      line-height: 12px;
      border: none;

      &.-disabled {
        background-color: rgba(0, 70, 183, 0.5);
      }
    }
    &-expand {
      padding: 2px 12px;
      border-radius: 2px;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.5);
      font-family: 'Beatrice', sans-serif;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: center;
      background-color: rgba(242, 242, 242, 1);
      width: 55px;
      box-sizing: border-box;
      border: none;
    }
    &-caret {
      height: 20px;
      width: 6px;
      margin-left: 3px;
      display: block;
      background-image: url("../../../../assets/img/FormulatorAdmin/CaretBig_dark.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: transform .1s ease;

      &.-open {
        transform: rotate(180deg);
      }
    }
    &-menu {
      position: absolute;
      top: 44px;
      right: 90px;
      z-index: 1;
      border-radius: 6px;
      box-shadow: 0 0 0 1px rgba(224, 224, 224, 1) inset, 0 5px 10px -5px rgba(0, 0, 0, 0.5);
      background-color: rgba(255, 255, 255, 1);
      display: flex;
      flex-direction: column;
      padding: 8px 4px;
      &-option {
        background: unset;
        border: none;
        color: rgba(0, 0, 0, 0.8);
        font-family: 'Beatrice', sans-serif;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        text-align: left;
        line-height: 20px;
        padding: 4px 12px;

        &:hover {
          border: none;
          background-color: rgba(242, 242, 242, 1);
        }
      }
    }
  }
}

.f-library {
  display: flex;
  &__row {
    padding-bottom: 8px;
  }
  &__flex-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__select {
    width: 100%;
    box-sizing: border-box;


    &-noOptions-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-text {
        color: rgba(0,0,0,0.5);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
      }

      &-button {
        width: auto;
        padding: 0;
        background-color: transparent;
        color: #0046B7;
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        text-transform: none;
      }
    }

    &.-is-disabled {
      opacity: 0.5;
    }

    &.-single {
      :global {
        .react-select__clear-indicator {
          display: flex !important;
        }
      }
    }

    &.-rm {
      input {
        box-shadow: none!important;
        &::placeholder {
          text-align: left!important;
        }
      }
      :global {
        .react-select__group {
          padding: 8px 4px!important;
          border-bottom: 1px solid #F2F2F2;
          &:first-child, &:last-child {
            border: none;
            padding: 0 4px!important;
          }
          &:nth-child(2n) {
            padding-top: 0!important;
          }
        }

        .react-select__control {
          width: 90%;
          min-height: 32px;
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 0;
          border-radius: 4px;
          padding-left: 6px;
          margin: auto;
          min-width: 60px;
        }
        .react-select__multi-value {
          max-width: 90%;
        }
        .react-select__indicator {
          background: transparent;
        }.react-select__placeholder {
        text-align: left;
                 }
        .react-select__dropdown-indicator:after {
          height: 7px;
        }
      }
    }

    :global {

      .react-select__menu {
        font-size: 12px !important;
        font-weight: normal !important;
      }
      .react-select__value-container {
        padding: 0;
        margin: 0;
      }

      .react-select__indicator {
        padding: 0;
      }

      .react-select__input-container {
        padding: 0;
        margin: 0;
      }

      .react-select__multi-value {
        height: 25px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        background-color: #FAFAFA;

        &__label {
          font-size: 12px;
        }
      }

      .react-select__multi-value__remove {
        opacity: 0.5;

        svg {
          width: 10px;
        }

        &:hover {
          background: transparent;
          color: #000000;
        }
      }

      .react-select__dropdown-indicator {
        height: 30px;
        width: 30px;
        border-radius: 5px;
        background-color: #F2F2F2;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
          content: '';
          height: 16px;
          width: 8px;
          display: block;
          background-image: url("../../../../assets/img/FormulatorAdmin/CaretBig_dark.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          transform: rotate(0deg);
        }

        svg {
          display: none;
        }

      }

      .react-select__clear-indicator, .react-select__indicator-separator {
        display: none;
      }

      .react-select__placeholder {
        padding-left: 0;
        line-height: 1;
      }

      .react-select__option {
        color: rgba(0,0,0,0.5);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
      }

      .react-select__control {
        padding: 7px 12px;
        width: 100%;
        border: none;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: inset 0 0 0 1px #E0E0E0;
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        font-weight: normal;

        input::placeholder {
          color: rgba(0,0,0,0.5);
          font-family: Beatrice, sans-serif;
          font-size: 12px;
          font-weight: normal;
        }
      }
      .react-select__option--is-selected {
        background: #FFFFFF;
      }
      .react-select__option--is-focused {
        background: #F2F2F2;
        cursor: pointer;
      }
    }

    &.-search-icon {
      :global {

        .react-select__value-container {
          position: relative;
        }

        .react-select__indicator {
          display: none;
        }

        .react-select__value-container--has-value {
          &:before {
            top: -2px;
          }
        }


      }
    }
  }
  &__input {
    width: 100%;
    max-width: 100%;
    min-width: 80%;
    min-height: 60px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: inset 0 0 0 1px #E0E0E0;
    border: none;
    padding: 12px;
    color: rgba(0,0,0,0.8);
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    box-sizing: border-box;

    &.-default {
      min-height: auto;
    }

    &.-error {
      box-shadow: inset 0 0 0 1px #FF5C6A;
    }

    &.-percentage {
      color: rgba(0,0,0,0.8);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      padding-right: 25px;
    }

    &.-with-currency-input {
      padding-left: 36px;
    }

    &::placeholder {
      color: rgba(0,0,0,0.5);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;

    }

    &:focus, &:active {
      outline: none;
    }
  }
  &__generate {
    border-radius: 6px;
    background-color: #0046B7;
    box-shadow: 0 12px 10px -5px rgba(0, 70, 183, 0.25);
    position: fixed;
    bottom: 16px;
    right: 40px;
    color: #FFFFFF;
    font-family: Beatrice, sans-serif;
    font-size: 12px!important;
    font-weight: 600!important;
    letter-spacing: 0.18px!important;
    line-height: 40px!important;
    /* padding-left: 50px!important; */
    /* position: relative; */
    padding: 5px 20px 5px 10px !important;
    text-transform: none !important;

    &:before {
      content: "";
      height: 32px;
      width: 32px;
      margin-right: 10px;
      background-image: url('../../../../assets/img/FormulatorAdmin/DownloadFile.svg');
    }
  }
  &__top-container {
    border-radius: 6px;
    background-color: #FAFAFA;
    margin-bottom: 32px;
    padding: 29px 64px;

    .f-library__right {
      color: rgba(0,0,0,0.8);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      a{
        color: rgba(0, 0, 0, 0.8)!important;
        text-decoration: none!important;
        pointer-events: none;
      }
    }

    .f-library__row {
      margin-bottom: 0;
      align-items: center;
    }
  }
  &__container {
    padding: 29px 64px;
  }
  &__right {
    margin: 8px 0 16px;
    padding: 8px 8px 8px;
    width: calc(100% - 16px);
    text-align: left;
    color: rgba(0,0,0,0.8);
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    min-height: 20px;
    box-shadow: inset 0 -1px 0 0 #F2F2F2;
    &-flex-row {
      display: flex;
      align-items: center;

      .-col {
        display: flex;
        align-items: center;
        width: 50%;
        min-width: 50%;
        max-width: 50%;

        .f-library__right {
          padding: 0;
          box-shadow: none;
        }

        .f-library__left {
          text-align: right;
        }
      }
    }

    &.-with-currency {
      position: relative;

      &:before {
        content: "$";
        position: absolute;
        top: 24px;
        height: 44px;
        width: 36px;
        text-align: center;
        color: rgba(0, 0, 0, 0.8);
        font-size: 12px;
        letter-spacing: 0;
        font-weight: normal;
        line-height: 44px;
      }
    }

    &-table-left {
      width: 73px;
      color: rgba(0,0,0,0.8);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 16px;
      margin-right: 24px;
    }

    &-table-right {
      color: rgba(0,0,0,0.8);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;

      span {
        color: rgba(0,0,0,0.5);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        padding-right: 4px;
        padding-left: 4px;

        &.-divider {
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }

    &-table-row {
      display: flex;
      align-items: center;
      box-shadow: inset 0 -1px 0 0 #F2F2F2;
      padding: 4px 0;
      &.-with-inputs {
        box-shadow: none;

        .f-library__right-table-right {
          display: flex;
          align-items: center;

          input {
            margin-right: 8px;
            margin-left: 8px;
          }
        }

        &.-view-mode {
          width: 300px;
          box-shadow: inset 0 -1px 0 0 #F2F2F2;
          padding: 10px 0;

          .f-library__right-table-right {
            justify-content: center;
            width: 100%;
          }
        }
      }
    }

    &.-ing-percentage {
      color: rgba(0,0,0,0.8) !important;
      font-family: Beatrice, sans-serif;
      font-size: 12px!important;
      font-weight: 600!important;
      letter-spacing: 0!important;
      line-height: 20px!important;
      display: flex;
      align-items: center;
      width: 140px;
      position: relative;

      &.-edit:after {
        content: "%";
        position: absolute;
        right: 10px;
        color: rgba(0,0,0,0.5);
      }

    }

    &.-m {
      color: rgba(0,0,0,0.8);
      font-family: Beatrice, sans-serif;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.12px;
      line-height: 26px;
    }

    &.-l {
      color: rgba(0,0,0,0.8);
      font-family: Beatrice, sans-serif;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: 34px;
    }
  }

}

.f-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding-right: 40px;
  height: 24px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  &-counter {
    position: absolute;
    right: 0;
    color: #0046B7;
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    font-weight: 600;
    top: calc(50% - 12px);
  }
  .-adminItem {
    display: inline-flex !important;
  }
  .-teamItem {
    display: inline-flex;
    align-items: center;
    margin-right: 4px;
    height: 24px;
    overflow: hidden;
    width: fit-content;
    &-first {
      align-items: center;
      margin-right: 4px;
      height: 24px;
      width: fit-content;
      line-height: 24px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
    }
    &.-three-dots {
      &:after {
        content: "...";
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        display: block;
        height: 100%;
        right: 0;
        top: 0;
        margin-left: 1px;
        position: relative;
      }
    }
  }
}

.website-id {
  margin-bottom: 12px;
}

.overflowHidden {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    .f-admin__table-warning-comment {
      display: block;
    }
    cursor: pointer;
  }
}

.loader {
  width: 70px;
  height: 70px;
  &-raw {
    width: 20px;
    height: 20px;
  }
}

.custom-file-upload {
  height: 40px;
  width: 94px;
  border-radius: 6px;
  background-color: #FAFAFA;
  color: rgba(0,0,0,0.8);
  font-family: Beatrice, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 40px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
}
:global {
  input[type="file"] {
    display: none;
  }

}

.-select-option {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 4px 8px;
  color: rgba(0,0,0,0.8);
  font-family: Beatrice, sans-serif;
  font-size: 12px;
  line-height: 20px;
  background-color: transparent;
  border: none;
  position: relative;
  justify-content: flex-start;
  &.-grey {
    pointer-events: none;
    .-select-checkbox {
      &:checked + label {
        box-shadow: inset 0 0 0 1px #d9d9d9!important;
        background-color: #d9d9d9!important;
      }
    }
  }
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
  }
  &.-all {
    color: rgba(0,0,0,0.8);
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid #E0E0E0;
  }
}
.-select-name {
  &.-unavailable {
    color: rgb(165, 165, 165);
  }
}

.-select-checkbox {
  margin-right: 8px;
  height: 12px;
  width: 12px;
  position: absolute;
  border-radius: 2px;
  border: transparent;
  opacity: 0;
  cursor: pointer;

  & + label {
    display: inline-block;
    width: 12px;
    height: 12px;
    box-shadow: inset 0 0 0 1px #e0e0e0;
    background-color: #FFFFFF;
    margin-right: 8px;

    border-radius: 2px;
    cursor: pointer;
  }

  &:checked + label {
    box-shadow: inset 0 0 0 1px #0046B7;
    background-color: #0046B7;
    background-image: url('../../../../assets/img/FormulatorAdmin/Checkmark.svg');
    background-size: 12px;
  }
}
.selected-list__hover {
  display: inline;
  white-space: nowrap;
  color: #0046B7;
  font-family: Beatrice, sans-serif;
  font-size: 12px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
}
.selected-list__td {
  line-height: 16px;

  &-name {
    font-family: Beatrice, sans-serif;
    font-weight: 600;
  }
}

:global {
  .page-theme-v1 {
    padding-bottom: 64px!important;
  }
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 17px;
  color: rgba(0,0,0,0.8);
  font-family: Beatrice, sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  &__arrow {
    height: 32px;
    width: 32px;
    border-radius: 2px;
    background-color: #FAFAFA;
    padding: 0 !important;
    background-image: url('../../../../assets/img/FormulatorAdmin/CaretBig_dark.svg');
    background-size: 8px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 8px;
    &.-right {
      transform: rotate(270deg);
    }
    &.-left {
      transform: rotate(90deg);
    }

  }
  &__all {
    margin-left: 8px;
    margin-right: 8px;
  }
  &__current {
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: inset 0 0 0 1px #E0E0E0;
    border: none;
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
    width: 44px;
    box-sizing: border-box;
    text-align: center;
    padding: 10px 12px;
    margin-right: 8px;
  }
}

.-checkmark {
  display: inline-block;
  height: 16px;
  width: 16px;
  background-image: url('../../../../assets/img/FormulatorAdmin/Checkmark-green.svg');
  border-radius: 2px;
  //background-color: rgba(255, 255, 255, 1);
  margin-bottom: -3px;
}

.logic__options {
  color: rgba(0,0,0,0.5);
  font-family: Beatrice, sans-serif;
  font-size: 12px;
  padding: 8px 12px;
  &:hover {
    background-color: rgb(222, 235, 255);
  }
  .-checkmark {
    display: inline-block;
    height: 16px;
    width: 16px;
    background-image: url('../../../../assets/img/FormulatorAdmin/Checkmark-green.svg');
    border-radius: 2px;
    //background-color: rgba(255, 255, 255, 1);
    margin-bottom: -3px;
  }
}
