@use 'src/scss/shared' as *;
.cookies-accept {
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  max-width: 405px;
  height: 60px;
  width: 452px;
  min-height: 108px;
  min-width: 461px;
  display: flex;
  align-items: center;
  padding: 24px 28px;
  box-sizing: border-box;
  border-radius: 6px 0 6px 0;
  background: linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 100%);
  box-shadow: 0 0 7px -1px rgba(0,0,0,0.45)!important;

  z-index: 99999;

  @include media(mobile) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 0;
    height: auto;
  }
  &__info {
    color: rgba(0,0,0,0.8);
    font-family: 'Beatrice', sans-serif;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 20px;
    max-width: 268px;
    @include media(mobile) {
      font-size: 11px;
    }
    a {
      color: rgba(0,0,0,0.8);
      font-family: 'Beatrice', sans-serif;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.2px;
      line-height: 13px;
      text-decoration: none;
      @include media(mobile) {
        font-size: 11px;
      }
    }
  }
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media(mobile) {
      padding: 8px 16px;
    }
  }
  &__btn {
    font-family: 'Beatrice', sans-serif;
    height: 48px;
    width: 100px;
    border-radius: 6px;
    background-color: rgba(#F9E0D6FF, 0.2);
    color: #FF5C6A;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 48px;
    text-align: center;
    display: flex;align-items: center;justify-content: center;
    @include media(mobile) {
      width: 84px;
      height: 40px;
      font-size: 12px;
    }
    &:hover {
      color: #ffffff;
    }
  }
}
