@import "node_modules/bulma/sass/utilities";
@import "node_modules/bulma/sass/helpers";
@import "shared";

img[src*="liadm"], img[src*="idpix"], img[src*="crss"] {
  display: none;
}

.gfp-app-wrap {
  overflow: hidden;
}

.page-theme-v2 {

  font-family: $body-font-family;
  font-size: $body-font-size;
  line-height: $body-line-height;
  color: rgba(0, 0, 0, 0.8);

  @include tablet {
    font-size: $body-font-size-tablet;
    line-height: $body-line-height-tablet;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  p {
    margin: 0;
  }

}
.disabled {
  width: 100%;
  height: 100%;
  background-color: gray;
  position: fixed;
  z-index: 99998;
  opacity: 0.2;
}
.link {
  color: $c-primary;
  transition: 0.2s;
  text-decoration: none;

  &:hover {
    color: $c-primary-link-hover;
  }

}
