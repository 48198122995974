/* Cera Pro */

@font-face {
  font-family: 'Cera Pro';
  src: url('../../assets/fonts/CeraPro/Cera-Pro-Regular.eot');
  src: url('../../assets/fonts/CeraPro/Cera-Pro-Regular.woff2') format('woff2'),
  url('../../assets/fonts/CeraPro/Cera-Pro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../../assets/fonts/CeraPro/Cera-Pro-Medium.eot');
  src: url('../../assets/fonts/CeraPro/Cera-Pro-Medium.woff2') format('woff2'),
  url('../../assets/fonts/CeraPro/Cera-Pro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../../assets/fonts/CeraPro/Cera-Pro-Bold.eot');
  src: url('../../assets/fonts/CeraPro/Cera-Pro-Bold.woff2') format('woff2'),
  url('../../assets/fonts/CeraPro/Cera-Pro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* Sentinel */

@font-face {
  font-family: 'Sentinel';
  src: url('../../assets/fonts/Sentinel/Sentinel-Light.eot');
  src: url('../../assets/fonts/Sentinel/Sentinel-Light.woff2') format('woff2'),
  url('../../assets/fonts/Sentinel/Sentinel-Light.woff') format('woff'),
  url('../../assets/fonts/Sentinel/Sentinel-Light.svg#Sentinel') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../../assets/fonts/Sentinel/Sentinel-Book.eot');
  src: url('../../assets/fonts/Sentinel/Sentinel-Book.woff2') format('woff2'),
  url('../../assets/fonts/Sentinel/Sentinel-Book.woff') format('woff'),
  url('../../assets/fonts/Sentinel/Sentinel-Book.svg#Sentinel') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../../assets/fonts/Sentinel/Sentinel-Bold.eot');
  src: url('../../assets/fonts/Sentinel/Sentinel-Bold.woff2') format('woff2'),
  url('../../assets/fonts/Sentinel/Sentinel-Bold.woff') format('woff'),
  url('../../assets/fonts/Sentinel/Sentinel-Bold.svg#Sentinel') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('../../assets/fonts/Poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins/Poppins-Regular.ttf');
}
