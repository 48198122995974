@use 'src/scss/shared_v2' as *;

.search-box {

  position: relative;

  &__input-wrap {
    position: relative;
    height: 48px;
  }

  &__btn-search {

    @include vertical-center;

    position: absolute;
    display: flex;
    right: 12px;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;

  }

  &__input {
    height: 100%;
    width: 100%;
    min-width: 246px;
    padding-right: 44px;
    background: #fff;
  }

  &__icon-search {

    width: 20px;
    height: 20px;

    [opacity="0.8"] {
      opacity: 1;
    }

  }

  &__dropdown {

    @include reset-ul;

    position: absolute;
    max-height: 250px;
    overflow-y: auto;
    left: 0;
    right: 0;
    top: 100%;
    transition: 0.5s;
    background: #fff;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 999;
    padding: 3px 0;
    cursor: default;
  }

  &__item {

    margin: 0 3px;
    font-size: 12px;

    &:hover {
      background-color: #F2F2F2;
    }

  }

  &__link {
    display: block;
    padding: 5px 13px;
    text-decoration: none;
    color: $c-dark-text;
  }

  &__msg {
    margin: 0 3px;
    padding: 5px 13px;
    font-size: 12px;
    color: $c-dark-text;
  }

}




