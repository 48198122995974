@use 'src/scss/shared_v2' as *;

.header {
  padding: 10px 0 !important;
  font-family: $body-font-family;
  line-height: $body-line-height;
  box-sizing: border-box;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.06);
  max-width: 100%;
  position: fixed;
  z-index: 99999;
  width: 100%;
  background: #ffffff;
  @include tablet-only {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__message {
    position: fixed;
    top: 56px;
    padding: 0 !important;
    width: 100%;
    z-index: 1000;
    max-width: 100%;
    &-second {
      top: 112px;
      @media screen and (max-width: 1280px){
        top: 140px;
      }
      @media screen and (max-width: 600px){
        top: 168px;
      }
      @media screen and (max-width: 418px){
        top: 195px;
      }
    }
    &-content {
      max-width: 1061px;
      margin-right: 32px;
      text-align: justify;
      @media screen and (max-width: 1280px){
        max-width: 70%;
      }
      @media screen and (max-width: 759px){
        margin-right: 15px;
      }
      @media screen and (max-width: 600px){
        max-width: calc(100% - 220px);
      }
      @media screen and (max-width: 516px){
        max-width: calc(100% - 200px);
      }
      @media screen and (max-width: 418px){
        width: 100%;
        max-width: 100%;
        padding:  0 15px;
      }

      &-maintenance {
        display: flex;
        width: calc(100% - 40px);
        justify-content: flex-start;
        align-items: center;
      }
    }
    &-text {
      width: calc(100% - 44px);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (max-width: 1280px){
        -webkit-line-clamp: 3;
      }
      @media screen and (max-width: 600px){
        -webkit-line-clamp: 4;
      }
      @media screen and (max-width: 418px){
        -webkit-line-clamp: 5;
      }
    }
    &-maintenanceIcon {
      content: ' ';
      width: 32px;
      height: 32px;
      margin-right: 12px;
      background: url('../../assets/img/FormulatorAdmin/maintenanceIcon.svg');
    }
    &-container {
      height: 56px;
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 100% !important;
      align-items: center;
      background-color: #FBEC12;
      color: rgba(0,0,0,0.8);
      font-family: Beatrice, sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      @media screen and (max-width: 1280px){
        height: 85px;
        padding: 0 20px;
      }
      @media screen and (max-width: 759px){
        font-size: 10px;
        letter-spacing: 0;
        line-height: 18px;
      }
      @media screen and (max-width: 600px){
        height: 113px;
      }
      @media screen and (max-width: 516px){
        line-height: 14px;
        padding: 0px;
      }
      @media screen and (max-width: 418px){
        height: 140px;
        flex-wrap: wrap;
      }
      button {
        padding: 0!important;
        height: 40px;
        width: 146px;
        border-radius: 6px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0,0,0,0.8);
        font-family: Beatrice, sans-serif;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        @media screen and (max-width: 759px){
          font-size: 10px;
          letter-spacing: 0;
          line-height: 16px;
          width: 120px;
        }
        &:hover {
          background-color: #FFFFFF!important;
        }
      }
    }
  }

  &__label {
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    color: rgba(0,0,0,0.5);
    font-family: Beatrice, sans-serif;
    padding: 2px 6px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.28px;
    line-height: 24px;
  }

  &__burger {
    display: inline-block;
    width: 32px;
    height: 32px;
    max-width: 32px;
    background: url('../../assets/img/FormulatorAdmin/Default.svg');
    margin-right: 16px;
    &-container {
      display: flex;
      align-items: center;
    }
    &.-active {
      background: url('../../assets/img/FormulatorAdmin/Actives.svg');
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__account {
    &-wrapper {
      width: calc(100% - 60px);
    }
    &-name {
      color: rgba(0,0,0,0.8);
      font-family: Beatrice, sans-serif;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.12px;
    }
    &-email {
      color: rgba(0,0,0,0.5);
      font-family: Beatrice, sans-serif;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 16px;
    }
    &-btn {
      width: 36px;
      height: 36px;
      padding: 0;
      background-color: #F2F2F2;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0,0,0,0.5);
      font-family: Beatrice, sans-serif;
      font-size: 14px;
      &:active {
        box-shadow: none!important;
      }
      &:hover {
        background-color: #F2F2F2!important;
        cursor: pointer;
      }
    }
    &-divider {
      height: 28px;
      background-color: #E0E0E0;
      width: 1px;
      margin: 0 16px;
    }
    &-tutorial {
      color: rgba(0,0,0,0.5);
      padding: 0;
      background: url('../../assets/img/FormulatorAdmin/tutorial.svg') !important;
      background-size: 32px!important;
      background-repeat: no-repeat;
      background-position: center;
      width: 32px;
      height: 32px;
      opacity: 0.6;
      margin-right: 8px;
      &:active {
        box-shadow: none!important;
      }
      &-active {
        opacity: 1;
        background: url('../../assets/img/FormulatorAdmin/tutorial_active.svg') !important;
      }
    }
    &-logout {
      color: rgba(0,0,0,0.5);
      padding: 0;
      background: url('../../assets/img/FormulatorAdmin/logout.png') !important;
      background-size: contain!important;
      background-repeat: no-repeat;
      background-position: center;
      width: 20px;
      height: 20px;
      opacity: 0.6;
      margin-right: 8px;
    }
    &-notification {
      color: rgba(0,0,0,0.5);
      padding: 0;
      background: url('../../assets/img/FormulatorAdmin/notifications.svg') !important;
      background-size: 32px!important;
      background-repeat: no-repeat;
      background-position: center;
      width: 32px;
      height: 32px;
      &:active {
        box-shadow: none!important;
      }
      &.-unread {
        position: relative;
        &:after {
          content: "";
          width: 5px;
          height: 5px;
          background: #FF5C6A;
          display: block;
          position: absolute;
          left: 17px;
          top: 8px;
          border-radius: 100%;
          border: 2px solid #ffffff;
          box-sizing: initial;
        }
      }
      &-active {
        background: url('../../assets/img/FormulatorAdmin/notifications_active.svg') !important;
      }
    }

  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto !important;
    max-width: 1980px !important;
    margin: 0 24px !important;
    @include fullhd {
      max-width: 100% !important;
      margin-left: 24px!important;
      margin-right: 24px!important;
    }
  }

  &__logo {
    max-width: 156px;
    width: 100%;
    margin-right: auto;

    @include tablet {
      max-width: 173px;
    }

    @include desktop {
      max-width: 172px;
    }

    @include widescreen {
      margin-right: 23px;
    }

    @include IE11 {
      svg {
        max-height: 24px;
      }
    }

  }

  &__menu {

    @include reset-ul;

    display: none;

    @include widescreen {

      display: flex;
      order: 3;
      margin-left: auto;

      li {
        position: relative;
        margin-right: 30px;
        color: $c-dark-text;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
      }

      & > li {
        transition: color 0.2s;

        &.accent {
          color: $c-dark-text;
        }

        &:hover {
          color: $c-primary-link-hover;
        }

      }

      a {
        color: inherit;
        text-decoration: none!important;
      }

      li:global(.has-children) {

        &:after {

          content: "";
          display: inline-block;
          @include arrow-regular(currentColor, 45, 2.8, 1.9);
          margin-left: 6px;
          margin-bottom: 2px;

        }

        &:hover > ul {
          display: block;
        }

        ul {

          @include reset-ul;

          display: none;
          position: absolute;
          top: calc(100% + 6px);
          left: 0;
          min-width: 205px;
          padding: 8px 0;
          border-radius: 6px;
          background-color: #FFFFFF;
          box-shadow: 0 7px 16px -3px rgba(0, 0, 0, 0.39);
          z-index: 999;

          &:before {
            content: "";
            position: absolute;
            bottom: 100%;
            height: 6px;
            width: 100%;
          }

          li {
            margin: 0;

            &:hover {
              background-color: rgba($c-secondary, 0.4);
            }

            a {
              display: block;
              padding: 4px 16px;
              font-weight: 400;
            }

          }

        }

      }

    }

    @include fullhd {

      li {
        margin-right: 40px;
      }

    }

  }



  &__menu-toggle {
    order: 7;
    width: 24px;
    height: auto;
    margin-left: 20px;

    @include tablet {
      box-sizing: border-box;
      width: 40px;
      padding: 8px;
      border-radius: 6px;
      background-color: rgba(249, 224, 214, .2);
    }

    @include desktop {
      width: 48px;
      padding: 13.5px 12px;
    }

  }
  &__menus {
    padding: 12px 0;
    position: absolute;
    top: 42px;
    z-index: 10000;
    right: 0;
    width: 281px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: inset 0 0 0 1px #E0E0E0, 0 5px 10px -5px rgba(0,0,0,0.5);
    .header__account-btn {
      width: 48px;
      min-width: 48px;
      height: 48px;
      font-size: 16px;
      font-weight: 600;
      margin-right: 12px;
      overflow: hidden;
    }
    &-info {
      display: flex;
      align-items: center;
      padding: 0 16px 12px 16px;
      border-bottom: 1px solid #F2F2F2;
    }
    &-menu {
      padding: 12px 16px 12px 16px;
      border-bottom: 1px solid #F2F2F2;
      margin-bottom: 12px;
      &-item {
        width: 100%;
        display: block;
        text-decoration: none!important;
        font-family: Beatrice, sans-serif;
        font-size: 14px;
        letter-spacing: -0.12px;
        margin: 2px 0;
        color: rgba(0,0,0,0.8);
        line-height: 24px;
        &-disabled {
          width: 100%;
          display: block;
          pointer-events: none;
          text-decoration: none!important;
          font-family: Beatrice, sans-serif;
          font-size: 14px;
          letter-spacing: -0.12px;
          margin: 2px 0;
          line-height: 24px;
          color: currentColor;
          opacity: 0.5;
          &:hover{
             cursor:not-allowed!important;
          }
        }
        &.-divided {
          width: calc(100% - 16px)!important;
          background: transparent;
          text-align: left;
          font-weight: normal;
          padding: 0 0 0 16px;
          &:active {
            box-shadow: none!important;
          }
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
}

.sidebar-header {
  &__main {
    color: rgba(0,0,0,0.8);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.12px;
    line-height: 24px;
    font-family: Beatrice, sans-serif;
  }
  &__secondary {
    color: rgba(0,0,0,0.5);
    font-size: 14px;
    letter-spacing: -0.12px;
    line-height: 24px;
    font-family: Beatrice, sans-serif;
  }
}

:global {
  .formulator-header{
    height: 56px;
    &.-with-global-message {
      position: relative;
      width: 100%;
      height: 112px;
      @media screen and (max-width: 1280px){
        height: 140px;
      }
      @media screen and (max-width: 600px){
        height: 168px;
      }
      @media screen and (max-width: 418px){
        height: 195px;
      }
    }
    &.-with-double-global-messages {
      position: relative;
      width: 100%;
      height: 168px;
      @media screen and (max-width: 1280px){
        height: 224px;
      }
      @media screen and (max-width: 600px){
        height: 280px;
      }
      @media screen and (max-width: 418px){
        height: 334px;
      }
    }
  }
}
