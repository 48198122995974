@use 'src/scss/shared_v2' as *;

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $body-font-family;
}

.heading {
  margin-top: 25px;
  margin-bottom: 22px;
  text-align: center;
  font-weight: 600;
  letter-spacing: -0.5px;

  @include tablet {
    margin-top: 20px;
  }

  @include desktop {
    margin-top: 27px;
  }

}

.success-msg {

  margin: 17px auto 44px;
  text-align: center;
  letter-spacing: -0.1px;
  font-weight: 600;

  @include tablet {
    max-width: 348px;
    margin-bottom: 36px;
  }

  @include desktop {
    max-width: 470px;
  }

}
