@use 'src/scss/shared_v2' as *;

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $body-font-family;
  padding: 0 40px 40px 40px;
  width: 384px;
  border: none;
  height: 286px;
  max-width: 480px!important;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 0;
    background-color: transparent;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../assets/img/FormulatorAdmin/DeleteCross.svg');

    &:hover, &:active, &:focus {
      background-color: transparent !important;
    }
  }
  &__icon {
    margin-right: 8px;
    margin-bottom: -6px;
    width: 24px;
    height: 24px;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-image: url('../../../assets/img/FormulatorAdmin/Group.svg');
    text-align: center;
  }
  &__title {
    color: rgba(0, 0, 0, 0.8);
    font-family: Beatrice, sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  &__msg {
    color: rgba(0, 0, 0, 0.8);
    font-family: Beatrice, sans-serif;
    font-size: 14px;
    letter-spacing: -0.12px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
    font-weight: 400;
    b {
      font-weight: 600;
    }
  }
  &__time {
    font-size: 32px;
    color: #FF5C6A;
    margin-bottom: 24px;
  }
  &__btn {
    border-radius: 6px;
    background: #F2F2F2;
    color: rgba(0,0,0, 0.8);
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 16px;
    padding: 4px 0;
    width: 100%;
    margin-right: 18px;
    margin-left: 20px;
    height: 48px;
    &:hover {
      background-color: #E0E0E0!important;
    }
  }
  &__btn-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

:global {
  .ReactModal__Content {
    padding: 20px !important;
  }
}
