@use 'src/scss/shared' as *;

.o-slide-down {

  width: 100%;

  &__header {

    cursor: pointer;

    &--has-icon {
      position: relative;
      display: flex;
      align-items: center;
    }

  }

  &__icon {

    position: absolute;
    right: 4px;
    transition: all 0.5s;
    cursor: pointer;
    opacity: .7;

    &--arrow {
      @include arrow-regular(#000, 45, 4);
    }

    &--arrow-sm {
      @include arrow-regular(#000, 45, 2);
    }

    &--arrow#{&}--active,
    &--arrow-sm#{&}--active {
      transform: rotate(-135deg);
    }

    &--plus-minus {

      $size: 8px;
      $thickness: 2px;

      width: $size;
      height: $size;

      &:before,
      &:after{
        content: "";
        position: absolute;
        background-color: black;
        transition: transform 0.25s ease-out;
      }

      /* Vertical line */
      &:before{
        top: 0;
        left: 50%;
        width: $thickness;
        height: 100%;
        margin-left: -$thickness/2;
      }

      /* horizontal line */
      &:after{
        top: 50%;
        left: 0;
        width: 100%;
        height: $thickness;
        margin-top: -$thickness/2;
      }

    }

    &--plus-minus-lg {

      $size: 16px;

      width: $size;
      height: $size;

    }

    &--plus-minus#{&}--active {
      &:before,
      &:after {
        background-color: $purple;
      }
      &:before{ transform: rotate(90deg); }
      &:after{ transform: rotate(180deg); }
    }

  }

}
