@use 'src/scss/shared_v2' as *;

.logo {

  display: flex;
  align-items: center;

  svg {
    width: 100%;
  }

}
