@use 'sass:color';

@import "../variables";

$c-primary: #ff5c6a;
$c-secondary: #f9e0d6;
$c-secondary-light: #FFF6F2;

$c-primary-link-hover: #F84958;
$c-primary-link-active: #FF707C;

$c-secondary-link-hover: #FFF6F2;
$c-secondary-link-active: #f9e0d6;

$c-dark-text: rgba(0, 0, 0, 0.75);

$body-font-family: Beatrice;

$body-font-size: 12px;
$body-font-size-tablet: 14px;

$body-line-height: 20px;
$body-line-height-tablet: 24px;

$purple: #FF5C6A; /* rgb(255, 92, 106) */
$light-purple: color.adjust($purple, $lightness: 10%); /* #FF8F99 or rgb(255, 143, 153) */
$dark-purple: color.adjust($purple, $lightness: -10%); /* #FF293B or rgb(255, 41, 59) */
