@use 'src/scss/shared_v2' as *;

.button {

  display: inline-block;
  padding: 16.5px 35px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  text-decoration: none!important;
  border: none;
  line-height: 1.2;
  cursor: pointer;
  transition: 0.2s;
  outline: none;

  &--primary {
    background: $c-primary;
    color: #FFFFFF;

    .arrow [fill="#FF5C6A"] {
      fill: #fff;
    }

    &:hover:not(:disabled) {
      background-color: $c-primary-link-hover;
    }

    &:active:not(:disabled) {
      background-color: $c-primary;
    }

  }

  &--secondary {
    background-color: rgba($c-secondary, 0.2);
    color: $c-primary;

    &:hover:not(:disabled) {
      background-color: $c-secondary-link-hover;
    }

    &:active:not(:disabled) {
      background-color: rgba($c-secondary, 0.2);
    }

  }

  span {
    margin-right: 12px;
  }

  &:active:not(:disabled) {
    box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.11);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;

    .arrow {
      display: none;
    }

    &:after {
      box-sizing: border-box;
      animation: spin 500ms infinite linear;
      border: 2px solid #fff;
      border-radius: 50%;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      content: "";
      display: block;
      height: 1.2em;
      top: 50%;
      margin-top: -0.6em;
      left: 50%;
      margin-left: -0.6em;
      position: absolute;
      width: 1.2em;
    }

  }

}

.link-button {

  display: inline-block;
  color: $c-primary;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: none;

  @include desktop {
    font-size: 16px;
  }

  span {
    margin-right: 12px;
  }

  &:hover {
    color: $c-primary-link-hover;
  }

  &:active {
    color: $c-primary-link-active;
  }


}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}
