@use 'src/scss/shared' as *;

.holder {

  background-color: #F0F0F0;

  .holder__inner {

    @include container;

    box-sizing: border-box;
    padding: 12px 40px 2px !important;

  }

}

.msg {
  position: relative;
  margin-bottom: 10px;
  color: rgba(255, 0, 0, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

  &__close {
    @include close-icon;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

}

