@use 'src/scss/shared_v2' as *;

.sidebar {
  position: fixed;
  right: 0;
  top: 56px;
  width: 565px;
  height: calc(100% - 56px);
  z-index: 1000;
  min-width: 320px;
  box-shadow: -15px 0 16px -15px rgba(0, 0, 0, 0.24);
  background-color: white;
  @include touch {
    top: 52px;
    width: 50%;
  }

  .header {
    width: 100%;
    height: 95px;
    background-color: #F2F2F2;

    &.-size-m {
      height: 56px;

      .header__content {
        padding: 16px;
      }
    }

    &__content {
      width: calc(100% - 50px);
      height: 100%;
      padding: 24px 24px 18px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__close {
      position: absolute;
      top: 17px;
      right: 17px;
      width: 32px;
      height: 32px;
      padding: 0;
      background-color: transparent;
      background-size: 32px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../assets/img/FormulatorAdmin/DeleteCross.svg');

      &:hover, &:active, &:focus {
        background-color: transparent !important;
      }
    }
  }

  .main-content {
    width: 100%;
    height: calc(100% - 95px);
    overflow-y: auto;

    & .contentWrapper {
      margin-bottom: 70px;
    }
  }

  &__scrollTop {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    background-color: #F2F2F2;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../assets/img/FormulatorAdmin/BackIconBlack.svg');
      background-size: 32px;
      opacity: 0.5;
      transform: rotate(90deg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.user-guide {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 20px;

  &__header {
    display: flex;
    justify-content: flex-start;

    & > .number {
      width: 40px;
      height: 40px;
      padding: 10px;
      background-color: #F2F2F2;
      border-radius: 5px;
      color: rgba(0, 0, 0, 0.5);
      font-family: Beatrice, sans-serif;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.12px;
      line-height: 26px;
      text-align: center;
      box-sizing: border-box;
    }

    & > .text {
      color: rgba(0, 0, 0, 0.8);
      font-family: Beatrice, sans-serif;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.12px;
      line-height: 20px;
      padding: 10px 20px;
    }
  }

  &__mainDescription {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.8);
    font-family: Beatrice, sans-serif;
    font-size: 14px;
    letter-spacing: -0.12px;
    line-height: 20px;
  }

  &__subHeading {
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.8);
    font-family: Beatrice, sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.12px;
    line-height: 20px;
    display: flex;
  }

  &__additionalPanel {
    margin-bottom: 24px;
    border-radius: 4px;
    background-color: #F2F2F2;
    padding: 12px 12px 12px 38px;
    position: relative;
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    color: rgba(0,0,0,0.8);


    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 2px;
      top: 2px;
      height: 24px;
      width: 24px;
      background-image: url('../../assets/img/FormulatorAdmin/Information.svg');
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: center;
    }

  }

  &__additionalDescription {
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.8);
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }

  &__image {
    margin-bottom: 24px;
    width: 100%;
    border: solid 1px #F2F2F2;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;

    & > img {
      display: block;
    }
  }

  &__warning {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.8);
    background-image: url('../../assets/img/FormulatorAdmin/Warning.svg');
    display: inline-block;
    padding: 6px;
    background-size: 12px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
    box-sizing: border-box;
  }
}

.accordion {
  width: 100%;
  padding: 12px 0;
  border-bottom: solid 1px #F2F2F2;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 4px 0;
  }

  &__content {
    display: none;
    flex-direction: column;
    padding: 12px 0 0 60px;
    overflow: hidden;

    &.show {
      display: flex;
    }
  }

  &__counter {
    float: right;
    min-width: 20px;
    height: 24px;
    border-radius: 2px;
    background-color: #F5F5F5;
    color: rgba(0, 0, 0, 0.5);
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    padding: 2px 15px;
    justify-content: flex-end;

    &-show {
      display: none;
    }

    &-caret {
      height: 16px;
      width: 6px;
      margin-left: 3px;
      display: block;
      background-image: url("../../assets/img/FormulatorAdmin/CaretBig_dark.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: transform .1s ease;

      &.-open {
        transform: rotate(180deg);
      }
    }
  }
}

.user-notifications {
    :global {
      .-checkmark-logic {
        display: inline-block;
        height: 16px;
        width: 16px;
        background-image: url('../../assets/img/FormulatorAdmin/Checkmark-green.svg');
        border-radius: 2px;
        margin-bottom: -3px;
      }
    }
  &__btn {
    text-decoration: none;
    padding: 4px 6px !important;
    border-radius: 2px;
    background-color: #0046b7;
    color: #ffffff;
    font-family: Beatrice, sans-serif;
    font-size: 10px !important;
    font-weight: 600 !important;
    letter-spacing: 0.25px !important;
    line-height: 24px !important;
    text-align: center !important;
    cursor: pointer!important;
  }
  &__divider {
    color: rgba(0, 0, 0, 0.5);
    font-family: Beatrice, sans-serif;
    font-size: 14px;
    letter-spacing: -0.12px;
    line-height: 24px;
    margin-top: 32px;
    padding-left: 16px;
  }

  &__item {
    padding: 16px 16px 16px 72px;
    box-shadow: inset 0 -1px 0 0 #F2F2F2;
    position: relative;

    &:before {
      content: "";
      height: 32px;
      width: 32px;
      display: block;
      position: absolute;
      left: 16px;
      top: 16px;
      background-image: url('../../assets/img/FormulatorAdmin/GeneralNotification.svg');

    }

    &.-unread {
      &:after {
        content: "";
        width: 5px;
        height: 5px;
        background: #FF5C6A;
        display: block;
        position: absolute;
        left: 38px;
        top: 17px;
        border-radius: 100%;
        border: 2px solid #ffffff;
      }
    }

  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  &__type {
    color: rgba(0, 0, 0, 0.5);
    font-family: Beatrice, sans-serif;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
  }

  &__date {
    color: rgba(0, 0, 0, 0.8);
    font-family: Beatrice, sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    text-align: right;
  }

  &__body {
    color: rgba(0, 0, 0, 0.8);
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
  }
}
