@use 'src/scss/shared_v2' as *;

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $body-font-family;
  padding: 48px!important;
  max-width: 480px!important;

  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    padding: 0;
    background-color: transparent;
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../assets/img/FormulatorAdmin/DeleteCross.svg');
    &:hover, &:active, &:focus {
      background-color: transparent!important;
    }
  }

  &__title {
    color: rgba(0,0,0,0.8);
    font-family: Beatrice, sans-serif;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 14px;
  }
  &__text {
    color: rgba(0,0,0,0.8);
    font-family: Beatrice, sans-serif;
    font-size: 14px;
    letter-spacing: -0.12px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 24px;
  }
  &__btn {
    width: 100%;
    padding: 4px;
    border-radius: 6px;
    background-color: #F2F2F2;
    color: black;
    font-family: Beatrice, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 40px;
    &:hover,&:focus, &:active {
      background-color: #b4b2b2!important;
    }
  }
  &__inf-block {
    display: flex;
  }
  &__inf-icon {
    content: "";
    background-image: url('../../../assets/img/FormulatorAdmin/Information.svg');
    width: 24px;
    height: 24px;
    margin-right: 10px;
    margin-top: -1px;

  }
}
