@use 'src/scss/shared_v2' as *;

.holder {
  position: relative;
  background-color: #FF5C6A;
  &.-fixed-header {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.app-btn {
  display: block;
  text-align: center;
  background: none !important;
  padding: 12px 10px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.2px;

  @include tablet {
    padding-top: 16px;
    padding-bottom: 16px;
  }

}

.close {
  @include close-icon(#fff);
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);

  @include tablet {
    right: 24px;
  }

}
